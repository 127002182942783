import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';
import { useParams, useNavigate } from 'react-router-dom';
import AgencyBusinessUserForm from './AgencyBusinessUserForm';
import { useBusinessContext } from '../../context/BusinessContext';


const AgencyBusinessUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { businessId } = useParams();
  const { adminId } = useBusinessContext();
  const [agencyId, setAgencyId] = useState(null);
  const [agencyName, setAgencyName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
   const [userToDelete, setUserToDelete] = useState(null);
   const [showSuccessToast, setShowSuccessToast] = useState(false);
const [successMessage, setSuccessMessage] = useState('');



  const [isFormOpen, setIsFormOpen] = useState(false);
    const [editingUser, setEditingUser] = useState(null);


  const navigate = useNavigate();

  const handleBack = () => {
  navigate(-1);
};



  useEffect(() => {
    fetchUsers();
    fetchAgencyInfo();
  }, [businessId]);

  const handleAddUser = () => {
    setEditingUser(null);
    setIsFormOpen(true);
  };

const handleSaveUser = (savedUser) => {
  if (editingUser) {
    setUsers(users.map(user => user.id === savedUser.id ? savedUser : user));
    setSuccessMessage('User updated successfully');
  } else {
    setUsers([...users, savedUser]);
    setSuccessMessage('New user added successfully');
  }
  setIsFormOpen(false);
  setEditingUser(null);
  setShowSuccessToast(true);
  setTimeout(() => setShowSuccessToast(false), 3000);
};


const fetchAgencyInfo = async () => {
    try {
      console.log('Fetching agency info for business ID:', businessId);
      const response = await axios.get(`${config.API_URL}/api/businesses/${businessId}?populate=agency`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });

      const fetchedAgencyId = response.data.data.attributes.agency.data.id;
      const fetchedBusinessName = response.data.data.attributes.name;
      setAgencyId(fetchedAgencyId);
      setBusinessName(fetchedBusinessName);
    } catch (error) {
      console.error('Error fetching agency info:', error);
    }
  };
  



  

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/users?filters[business][id][$eq]=${businessId}&populate=role`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  const handleEditUser = (user) => {
  setEditingUser(user);
  setIsFormOpen(true);
};

const handleDeleteUser = (userId) => {
  setUserToDelete(userId);
  setShowConfirmModal(true);
};

const performDelete = async () => {
  try {
    await axios.delete(`${config.API_URL}/api/users/${userToDelete}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
    setUsers(users.filter(user => user.id !== userToDelete));
    setShowConfirmModal(false);
  } catch (error) {
    console.error('Error deleting user:', error);
  }
};




  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      
      <div className="flex justify-between items-center mb-6">
    <div>
    {businessName && (
      <h1 className="text-indigo-600 text-3xl font-bold">{businessName}</h1>
    )}

    </div>
    <div className="flex items-center space-x-4">
      <button
        onClick={handleBack}
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
      >
        Back
      </button>
      <button
        onClick={handleAddUser}
        className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
      >
        Add New User
      </button>
    </div>
  </div>


      <div className="bg-white shadow-md rounded my-6">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-100 border-gray-100 text-gray-600 text-sm leading-normal">
              <th className="py-3 px-6 text-left">Username</th>
              <th className="py-3 px-6 text-left">Email</th>
              <th className="py-3 px-6 text-center">Role</th>
              <th className="py-3 px-6 text-center">Status</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {users.map((user) => (
              <tr key={user.id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  <span className="font-medium">{user.username}</span>
                </td>
                <td className="py-3 px-6 text-left">
                  <span>{user.email}</span>
                </td>
                <td className="py-3 px-6 text-center">
                  <span className="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs">
                    {user.role && user.role.name ? user.role.name : 'N/A'}
                  </span>
                </td>
                <td className="py-3 px-6 text-center">
                  <span className={`py-1 px-3 rounded-full text-xs ${user.blocked ? 'bg-red-200 text-red-600' : 'bg-green-200 text-green-600'}`}>
                    {user.blocked ? 'Blocked' : 'Active'}
                  </span>
                </td>

                <td className="py-3 px-6 text-center">
        <div className="flex item-center justify-center">
          <button onClick={() => handleEditUser(user)} className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded text-xs mr-2">
            Edit
          </button>

          {users.length === 1 ? (
  <button 
    disabled 
    className="bg-gray-300 text-gray-500 font-bold py-1 px-2 rounded text-xs cursor-not-allowed" 
    title="Cannot delete the only user"
  >
    Delete
  </button>
) : (
  <button 
    onClick={() => handleDeleteUser(user.id)} 
    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-xs"
  >
    Delete
  </button>
)}



        </div>


      </td>


              </tr>
            ))}
          </tbody>
        </table>
      </div>


      <AgencyBusinessUserForm
  isOpen={isFormOpen}
  onClose={() => setIsFormOpen(false)}
  onSave={handleSaveUser}
  editingUser={editingUser}
  businessId={businessId}
  agencyId={agencyId}
  adminId={adminId}
/>


{showConfirmModal && (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      <div className="mt-3 text-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Confirm Deletion</h3>
        <div className="mt-2 px-7 py-3">
          <p className="text-sm text-gray-500">
            Are you sure you want to delete this user? This action cannot be undone.
          </p>
        </div>
        <div className="items-center px-4 py-3">
            
          <button
            id="ok-btn"
            className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
            onClick={performDelete}
          >
            Delete User
          </button>

          <button
            id="cancel-btn"
            className="mt-3 px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
            onClick={() => setShowConfirmModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
)}

{showSuccessToast && (
  <div className="fixed bottom-5 right-5 bg-green-500 text-white px-4 py-2 rounded-md">
    {successMessage}
  </div>
)}


    </div>
  );
};

export default AgencyBusinessUsers;
