import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config/config'; // Adjust the path based on your project structure

const AddContact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleAddContact = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.API_URL}/api/contacts`, {
        name,
        email,
        phone,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      console.log('Contact added:', response.data);
      navigate('/contacts'); // Redirect to contacts list
    } catch (error) {
      console.error('Error adding contact:', error);
      setError('Failed to add contact');
    }
  };

  return (
    <div>
      <h1>Add Contact</h1>
      {error && <p>{error}</p>}
      <form onSubmit={handleAddContact}>
        <div>
          <label>Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label>Phone</label>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <button type="submit">Add Contact</button>
      </form>
    </div>
  );
};

export default AddContact;
