import React, { createContext, useState, useContext, useEffect } from 'react';

const BusinessContext = createContext();


//role: 3 : admin
//role: 4 : agency
//role: 2 : public
//role: 1 : authenticated
//role: 5 : staff
//role: 7 : owner
//role: 6 : not exist


export const BusinessProvider = ({ children }) => {
  console.log('BusinessProvider: Initializing');
  const [loading, setLoading] = useState(true);
  const [businessContext, setBusinessContext] = useState(() => {
    const token = localStorage.getItem('jwt');
    console.log('BusinessProvider: Initializing state');
    const storedBusinessId = localStorage.getItem('businessId');
    const storedUserData = JSON.parse(localStorage.getItem('userData') || 'null');
    const storedUserRoles = JSON.parse(localStorage.getItem('userRoles') || 'null');
    const storedUserType = localStorage.getItem('userType');
    const storedAgencyId = localStorage.getItem('agencyId');
    const storedAdminId = localStorage.getItem('adminId');
    if (!token) {
      localStorage.removeItem('businessId');
      localStorage.removeItem('userData');
      localStorage.removeItem('userRoles');
      localStorage.removeItem('agencyId');
    }
    console.log('BusinessProvider: Stored businessId:', storedBusinessId);
    return {
      businessId: storedBusinessId ? parseInt(storedBusinessId, 10) : null,
      userData: storedUserData,
      userRoles: storedUserRoles || { id: null, type: null },
      userType: storedUserType || null,
      agencyId: storedAgencyId ? parseInt(storedAgencyId, 10) : null,
      adminId: storedAdminId ? parseInt(storedAdminId, 10) : null,
    };
  });
  
  useEffect(() => {
    console.log('BusinessContext: context changed to', businessContext);
    if (businessContext.businessId !== null) {
      console.log('BusinessContext: Storing businessId in localStorage');
      localStorage.setItem('businessId', businessContext.businessId.toString());
    } else {
      console.log('BusinessContext: Removing businessId from localStorage');
      localStorage.removeItem('businessId');
    }
    if (businessContext.userData) {
      localStorage.setItem('userData', JSON.stringify(businessContext.userData));
    } else {
      localStorage.removeItem('userData');
    }
    if (businessContext.userRoles) {
      localStorage.setItem('userRoles', JSON.stringify(businessContext.userRoles));
    } else {
      localStorage.removeItem('userRoles');
    }
    if (businessContext.userType) {
      localStorage.setItem('userType', businessContext.userType);
    } else {
      localStorage.removeItem('userType');
    }
    if (businessContext.agencyId !== null) {
      localStorage.setItem('agencyId', businessContext.agencyId.toString());
    } else {
      localStorage.removeItem('agencyId');
    }

    if (businessContext.adminId !== null) {
      localStorage.setItem('adminId', businessContext.adminId.toString());
    } else {
      localStorage.removeItem('adminId');
    }



    setLoading(false);
  }, [businessContext]);

  const updateBusinessContext = (newContext) => {
    console.log('BusinessContext: Updating context to', newContext);
    setBusinessContext(prevContext => {
      console.log('BusinessContext: Previous context', prevContext);
      const updatedContext = { ...prevContext, ...newContext };
      console.log('BusinessContext: Updated context', updatedContext);
      return updatedContext;
    });
  };

  console.log('BusinessProvider: Rendering with context', businessContext);
  return (
    <BusinessContext.Provider value={{
      ...businessContext,
      setBusinessContext: updateBusinessContext,
      loading
    }}>
      {children}
    </BusinessContext.Provider>
  );
};

export const useBusinessContext = () => {
  console.log('useBusinessContext: Called');
  const context = useContext(BusinessContext);
  if (!context) {
    console.error('useBusinessContext: Context is undefined');
    throw new Error('useBusinessContext must be used within a BusinessProvider');
  }
  console.log('useBusinessContext: Returning context', context);
  return context;
};
