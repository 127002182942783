import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';
import { useBusinessContext } from '../../context/BusinessContext';
import BusinessAccountForm from './BusinessAccountForm';
import { useNavigate } from 'react-router-dom';

const BusinessAccounts = () => {
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingBusiness, setEditingBusiness] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [businessToDelete, setBusinessToDelete] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { userData, userRoles, agencyId, adminId  } = useBusinessContext();
  const [error, setError] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    if (userData && userRoles && agencyId) {
      fetchBusinesses();
    }
  }, [userData, userRoles, agencyId]);

  const fetchBusinesses = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/businesses?filters[agency][id][$eq]=${agencyId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      console.log('Full businesses response:', response);
      setBusinesses(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching businesses:', error);
      setLoading(false);
    }
  };
  

  const handleDeleteBusiness = (businessId) => {
    setBusinessToDelete(businessId);
    setShowConfirmModal(true);
  };

  const performDelete = async () => {
    try {
      await axios.delete(`${config.API_URL}/api/businesses/${businessToDelete}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      setBusinesses(businesses.filter(business => business.id !== businessToDelete));
      setShowConfirmModal(false);
      setSuccessMessage('Business deleted successfully');
      setShowSuccessToast(true);
      setTimeout(() => setShowSuccessToast(false), 3000);
    } catch (error) {
      console.error('Error deleting business:', error);
      setError(error.response?.data?.error?.message || 'An error occurred while deleting');
    }
  };

  const handleEditBusiness = (business) => {
    setEditingBusiness(business);
    setIsFormOpen(true);
  };

  const handleSaveBusiness = (savedBusiness) => {
    if (editingBusiness) {
      setBusinesses(businesses.map(b => b.id === savedBusiness.id ? savedBusiness : b));
      setSuccessMessage('Business updated successfully');
    } else {
      setBusinesses([...businesses, savedBusiness]);
      setSuccessMessage('New business added successfully');
    }
    setIsFormOpen(false);
    setEditingBusiness(null);
    setShowSuccessToast(true);
    setTimeout(() => setShowSuccessToast(false), 3000);
  };

  const handleAddBusiness = () => {
    setEditingBusiness(null);
    setIsFormOpen(true);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-indigo-600 text-3xl font-bold">Business Accounts</h1>
        <button
          onClick={handleAddBusiness}
          className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
        >
          Add New Business
        </button>
      </div>
      
      {/* Table for businesses */}
      {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}

      <div className="bg-white shadow-md rounded my-6">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-100 border-gray-100 text-gray-600 text-sm leading-normal">
              <th className="py-3 px-6 text-left">Business Name</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {businesses.map((business) => (
              <tr key={business.id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  <div className="flex items-center">
                    <span className="font-medium">{business.attributes?.name || 'N/A'}</span>
                  </div>
                </td>
                

                <td className="py-3 px-6 text-right">
                  <div className="flex item-center justify-center">

                  <button
                    onClick={() => navigate(`/agency/businesses/${business.id}/users`)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs mr-2"
                  >
                    View Users
                  </button>



                  <button 
      onClick={() => handleEditBusiness(business)} 
      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded text-xs mr-2"
    >
      Edit
    </button>
    <button 
      onClick={() => handleDeleteBusiness(business.id)} 
      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-xs"
    >
      Delete
    </button>


                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <BusinessAccountForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        onSave={handleSaveBusiness}
        editingBusiness={editingBusiness}
        adminId={adminId}
      />

      {/* Confirmation Modal */}
      {showConfirmModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Confirm Deletion</h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500">
                  Are you sure you want to delete this business? This action cannot be undone.
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  id="ok-btn"
                  className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  onClick={performDelete}
                >
                  Delete Business
                </button>
                <button
                  id="cancel-btn"
                  className="mt-3 px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  onClick={() => setShowConfirmModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Toast */}
      {showSuccessToast && (
        <div className="fixed bottom-5 right-5 bg-green-500 text-white px-4 py-2 rounded-md">
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default BusinessAccounts;
