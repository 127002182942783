import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';

const AgencyBusinessForm = ({ isOpen, onClose, onSave, editingBusiness, agencyId,adminId }) => {
  const [formData, setFormData] = useState({
    name: '',
    ownerName: '',
    ownerEmail: '',
    ownerPassword: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (editingBusiness) {
      setFormData({
        name: editingBusiness.attributes.name,
        ownerName: editingBusiness.attributes.owner?.data?.attributes?.username || '',
        ownerEmail: editingBusiness.attributes.owner?.data?.attributes?.email || '',
        ownerPassword: '',
        confirmPassword: ''
      });
    } else {
      setFormData({
        name: '',
        ownerName: '',
        ownerEmail: '',
        ownerPassword: '',
        confirmPassword: ''
      });
    }
  }, [editingBusiness]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    if (formData.ownerPassword !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
  
    try {
      let response;
      if (editingBusiness) {
        response = await axios.put(`${config.API_URL}/api/businesses/${editingBusiness.id}`, {
          data: {
            name: formData.name,
          }
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
      } else {
        response = await axios.post(`${config.API_URL}/api/businesses`, {
          data: {
            name: formData.name,
            agency: agencyId,
            admin: adminId
            
          }
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
  
        await axios.post(`${config.API_URL}/api/users`, {
          username: formData.ownerName,
          email: formData.ownerEmail,
          password: formData.ownerPassword,
          role: 7,
          business: response.data.data.id,
          admin: adminId,
          agency: agencyId
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
      }
      
      const completeBusinessData = await axios.get(`${config.API_URL}/api/businesses/${response.data.data.id}?populate=owner`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      
      onSave(completeBusinessData.data.data);
      onClose();
    } catch (error) {
      console.error('Error saving business:', error);
      setError(error.response?.data?.error?.message || 'An error occurred while saving');
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto p-6">
          <h2 className="text-indigo-600 text-2xl mb-4">{editingBusiness ? 'Edit Business' : 'Add New Business'}</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Business Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>
            {!editingBusiness && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Business Owner Name</label>
                  <input
                    type="text"
                    name="ownerName"
                    value={formData.ownerName}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Business Owner Email</label>
                  <input
                    type="email"
                    name="ownerEmail"
                    value={formData.ownerEmail}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Owner Password</label>
                  <input
                    type="password"
                    name="ownerPassword"
                    value={formData.ownerPassword}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {error && <p className="text-red-500 text-xs italic mb-4 px-6">{error}</p>}
        <div className="flex justify-end p-4 border-t">
          <button onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-800 mr-2">
            Cancel
          </button>
          <button 
            onClick={handleSubmit} 
            className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600"
          >
            {editingBusiness ? 'Update' : 'Create'}
          </button>
        </div>
      </div>
    </div>
  );
};  

export default AgencyBusinessForm;
