import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config/config';
import { useBusinessContext } from '../context/BusinessContext';
import ErrorBoundary from './ErrorBoundary';

import ContactCard from './ContactCard';
import ActivityCard from './ActivityCard';
import ScheduleList from './ScheduleList';
import ScheduleForm from './ScheduleForm';
import ScheduleApi from './ScheduleApi';
import EditCardSidebar from './EditCardSidebar';

const CardView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [card, setCard] = useState(null);
    const [stages, setStages] = useState([]);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [activeTab, setActiveTab] = useState('activities');
    const [isScheduleFormOpen, setIsScheduleFormOpen] = useState(false);
    const [editingSchedule, setEditingSchedule] = useState(null);
    const { businessId, userData } = useBusinessContext();
    console.log('BusinessContext data:', { businessId, userData });
    const scheduleListRef = useRef();
    const [schedules, setSchedules] = useState([]);
    const [scheduleUpdateTrigger, setScheduleUpdateTrigger] = useState(0);

    const [isEditSidebarOpen, setIsEditSidebarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


useEffect(() => {
  console.log('CardView businessId:', businessId);
  fetchCardData();
}, [id, businessId]);

  useEffect(() => {
    fetchCardData();
  }, [id]);

  useEffect(() => {
    if (showSuccessToast) {
      const timer = setTimeout(() => {
        setShowSuccessToast(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessToast]);

  const handleCreateNewSchedule = () => {
    setEditingSchedule(null);
    setIsScheduleFormOpen(true);
  };

  const handleEditSchedule = (schedule) => {
    setEditingSchedule(schedule);
    setIsScheduleFormOpen(true);
  };

  const handleCloseScheduleForm = (scheduleChanged = false) => {
    setIsScheduleFormOpen(false);
    setEditingSchedule(null);
    if (scheduleChanged && scheduleListRef.current) {
      scheduleListRef.current.fetchSchedules();
      setScheduleUpdateTrigger(prev => prev + 1);
    }
  };

  const handleEditCard = () => {
  setIsEditSidebarOpen(true);
};



  const fetchCardData = async () => {
    setIsLoading(true);
  try {
    const response = await axios.get(`${config.API_URL}/api/cards/${id}?populate=*`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
    console.log('Full card data response:', response);

    if (response.data && response.data.data && validateCardData(response.data.data)) {
      setCard(response.data.data);
      await fetchStages(response.data.data);

      const schedulesResponse = await ScheduleApi.getSchedules(id);
      console.log('Schedules response:', schedulesResponse);
      setSchedules(schedulesResponse);
    } else {
      console.error('Invalid or incomplete card data:', response.data);
      throw new Error('Invalid card data structure');
    }
    setIsLoading(false);
  } catch (error) {
    console.error('Error fetching card data:', error);
    setIsLoading(false);
    // Handle error state here
  }
};




  const fetchStages = async (cardData) => {
    if (!cardData || !cardData.attributes.pipeline) {
      console.error('Card data or pipeline not available');
      return;
    }
    try {
      const pipelineId = cardData.attributes.pipeline.data.id;
      const response = await axios.get(`${config.API_URL}/api/pipelines/${pipelineId}?populate[stages][fields][0]=name`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      setStages(response.data.data.attributes.stages.data);
    } catch (error) {
      console.error('Error fetching stages:', error);
    }
  };

  const updateStage = async (newStageId) => {
    const currentStageId = Number(card.attributes.stage.data.id);
    const newStageIdNumber = Number(newStageId);

    console.log('Current stage:', currentStageId);
    console.log('New stage:', newStageId);

    if (currentStageId === newStageId) {
      console.log('Stopping: same stage detected');
      setSuccessMessage('Card is already in this stage');
      setShowSuccessToast(true);
      return;
    }

    try {
      const newStageCardsResponse = await axios.get(`${config.API_URL}/api/cards?filters[stage][id][$eq]=${newStageId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      const cardsInNewStage = newStageCardsResponse.data.data;
      const newOrder = cardsInNewStage.length;

      const oldStageCardsResponse = await axios.get(`${config.API_URL}/api/cards?filters[stage][id][$eq]=${currentStageId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      let cardsInOldStage = oldStageCardsResponse.data.data;

      cardsInOldStage = cardsInOldStage.filter(c => c.id !== card.id);

      await axios.put(`${config.API_URL}/api/cards/${card.id}`, {
        data: {
          stage: newStageId,
          order: newOrder
        }
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });

      const updateOldStageCards = cardsInOldStage.map((c, index) =>
        axios.put(`${config.API_URL}/api/cards/${c.id}`, {
          data: { order: index }
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        })
      );

      await Promise.all(updateOldStageCards);

      const newStage = stages.find(stage => stage.id == newStageId);

      setCard(prevCard => ({
        ...prevCard,
        attributes: {
          ...prevCard.attributes,
          stage: {
            data: {
              id: newStageId,
              attributes: {
                name: newStage ? newStage.attributes.name : 'Unknown Stage'
              }
            }
          },
          order: newOrder
        }
      }));

      setSuccessMessage('Card stage updated successfully');
      setShowSuccessToast(true);
      fetchCardData();
    } catch (error) {
      console.error('Error updating stage:', error);
    }
  };



const handleUpdateCard = async (updatedCardData) => {
  setIsLoading(true);
  try {
    console.log('Updating card with data:', updatedCardData);
    const response = await axios.put(`${config.API_URL}/api/cards/${card.id}`, {
      data: updatedCardData
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
    console.log('Full API response:', response);

    if (response.data && response.data.data && response.data.data.attributes) {
      const updatedCard = response.data.data;
      if (validateCardData(updatedCard)) {
        setCard(updatedCard);
        setIsEditSidebarOpen(false);
        fetchCardData();
      } else {
        throw new Error('Invalid card data structure');
      }
    } else {
      console.error('Unexpected API response structure:', response);
      throw new Error('Invalid API response structure');
    }
  } catch (error) {
    console.error('Error updating card:', error);
    // Optionally, add an error message here
    setIsLoading(false);
  }
};

const validateCardData = (cardData) => {
  const requiredFields = ['name', 'description', 'stage', 'pipeline', 'contact'];
  for (let field of requiredFields) {
    if (!cardData.attributes.hasOwnProperty(field) || cardData.attributes[field] === null) {
      console.error(`Missing or null field in card data: ${field}`);
      return false;
    }
  }
  return true;
};

  if (!card || !card.attributes) return <div>.</div>;
  const pipelineName = card.attributes.pipeline?.data?.attributes?.name || 'Unknown Pipeline';


  console.log('Rendering ScheduleForm with props:', {
  isOpen: isScheduleFormOpen,
  scheduleItem: editingSchedule,
  cardId: id,
  businessId: businessId,
  contactId: card.attributes.contact?.data?.id
});

if (!card || !card.attributes) return <div>No card data available</div>;

// if (isLoading) return <div>Loading...</div>;

// if (isLoading) {
//   return (
//     <div className="flex justify-center items-center h-screen">
//       <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
//     </div>
//   );
// }

if (isLoading) {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
    </div>
  );
}


return (
  <ErrorBoundary>
    <div className="w-full p-6 bg-white">
      {card && card.attributes ? (
        <div className="flex gap-6">
          {/* First Column (30%) */}
          
          <div className="w-1/4 border-r-2 border-gray-200 p-4">
  <button
    onClick={() => navigate(-1)}
    className="mb-4 text-indigo-500 hover:text-indigo-700 flex items-center"
  >
    <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
    </svg>
    Back to Pipeline
  </button>

  <div className="mb-6">
    <h1 className="text-2xl font-bold mb-2">{card.attributes.name}</h1>

    <div className="text-gray-600 mb-4">
    <div>Pipeline: {pipelineName}</div>
    <div>Stage: {card.attributes.stage?.data?.attributes?.name || 'Unknown Stage'}</div>
  </div>
  

    <button
      onClick={handleEditCard}
      className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 transition duration-300 ease-in-out"
    >
      Edit Card
    </button>
  </div>

  <ContactCard contact={card.attributes.contact?.data} />

  <div className="mt-6">
    <h2 className="text-lg font-semibold mb-2">Description</h2>
    <p className="text-gray-700">{card.attributes.description || 'No description'}</p>
  </div>
</div>


          {/* Second Column (70%) */}
          <div className="w-3/4">
            <div className="bm-4">
              <button
                className={`px-4 py-2 mr-2 ${
                  activeTab === 'activities'
                    ? 'border-b-2 border-indigo-500 font-bold'
                    : 'text-gray-500 hover:text-gray-700'
                } transition-colors duration-300`}
                onClick={() => setActiveTab('activities')}
              >
                Activities
              </button>
              <button
                className={`px-4 py-2 mr-2 ${
                  activeTab === 'schedule'
                    ? 'border-b-2 border-indigo-500 font-bold'
                    : 'text-gray-500 hover:text-gray-700'
                } transition-colors duration-300`}
                onClick={() => setActiveTab('schedule')}
              >
                Schedule
              </button>
              <button
                className={`px-4 py-2 ${
                  activeTab === 'documents'
                    ? 'border-b-2 border-indigo-500 font-bold'
                    : 'text-gray-500 hover:text-gray-700'
                } transition-colors duration-300`}
                onClick={() => setActiveTab('documents')}
              >
                Documents
              </button>
            </div>

            {activeTab === 'activities' && (
              <ActivityCard 
                cardId={id}
                businessId={businessId}
                contactId={card.attributes.contact?.data?.id}
                userId={userData?.id}
                onActivityAdded={fetchCardData} 
              />
            )}
            {activeTab === 'schedule' && (
              <ScheduleList
                ref={scheduleListRef}
                key={scheduleUpdateTrigger}
                cardId={id}
                onCreateNew={handleCreateNewSchedule}
                onEditItem={handleEditSchedule}
              />
            )}
            {activeTab === 'documents' && (
              <div>Hello from Documents (to be implemented)</div>
            )}
          </div>

          <ScheduleForm
            isOpen={isScheduleFormOpen}
            onClose={handleCloseScheduleForm}
            scheduleItem={editingSchedule}
            cardId={id}
            businessId={businessId}
            contactId={card.attributes.contact?.data?.id}
          />

          <EditCardSidebar
            isOpen={isEditSidebarOpen}
            onClose={() => setIsEditSidebarOpen(false)}
            onSave={handleUpdateCard}
            stages={stages}
            businessId={businessId}
            pipelineId={card.attributes.pipeline.data.id}
            editingCard={card}
          />
        </div>
      ) : (
        <div></div>
      )}

      {showSuccessToast && (
        <div className="fixed bottom-0 right-0 m-4 p-4 bg-green-500 text-white rounded shadow-lg">
          {successMessage}
        </div>
      )}
    </div>
  </ErrorBoundary>
);

}


export default CardView;