import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config/config';
import { useBusinessContext } from '../context/BusinessContext';

const Header = ({ logout }) => {
  const [businessName, setBusinessName] = useState('');
  const [username, setUsername] = useState('');
  const { userRoles, agencyId } = useBusinessContext();
  const [agencyName, setAgencyName] = useState('');
  const { adminId } = useBusinessContext();
  const [adminName, setAdminName] = useState('');


  console.log('Header: userRoles:', userRoles);
  const isOwner = userRoles && userRoles.type === 'owner';
  console.log('Header: isOwner:', isOwner);

  const hasPermission = (allowedRoles) => {
    return allowedRoles.includes(userRoles.type);
  };

  useEffect(() => {
  const fetchData = async () => {
    try {
      if (agencyId) {
        const agencyResponse = await axios.get(`${config.API_URL}/api/agencies/${agencyId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
        setAgencyName(agencyResponse.data.data.attributes.name);
      }

      if (adminId) {
        const adminResponse = await axios.get(`${config.API_URL}/api/admins/${adminId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
        setAdminName(adminResponse.data.data.attributes.name);
      }

      if (userRoles && (userRoles.type === 'owner' || userRoles.type === 'staff')) {
        const businessId = localStorage.getItem('businessId');
        if (businessId) {
          const businessResponse = await axios.get(`${config.API_URL}/api/businesses/${businessId}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
          });
          setBusinessName(businessResponse.data.data.attributes.name);
        }
      }

      const userResponse = await axios.get(`${config.API_URL}/api/users/me`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      setUsername(userResponse.data.username);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, [agencyId, adminId, userRoles]);



  return (
    <header className="bg-indigo-600 shadow-md border-b border-indigo-700">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-1">


{hasPermission(['agency','owner', 'staff']) && agencyName && (
          <span className="text-white px-3 py-2 rounded-md text-sm font-medium">
            <i className="fas fa-building mr-2"></i>{agencyName}
          </span>
        )}

{hasPermission(['admin']) && adminName && (
  <span className="text-white px-3 py-2 rounded-md text-sm font-medium">
    <i className="fas fa-user-tie mr-2"></i>{adminName}
  </span>
)}


          <nav className="hidden md:flex space-x-4">

          {hasPermission(['owner', 'staff']) && (
            <>

            <Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/dashboard">
              <i className="fas fa-tachometer-alt mr-2"></i>Dashboard
            </Link>

            <Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/pipelines">
              <i className="fas fa-project-diagram mr-2"></i>Pipelines
            </Link>

            <Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/contacts">
              <i className="fas fa-address-book mr-2"></i>Contacts
            </Link>

            <Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/Products">
              <i className="fas fa-box mr-2"></i>Products
            </Link>

            <Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/companies">
              <i className="fas fa-building mr-2"></i>Companies
            </Link>
            </>

            )}


{hasPermission(['admin']) && (
  <Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/admin/dashboard">
    <i className="fas fa-tachometer-alt mr-2"></i>Dashboard
  </Link>
)}


{hasPermission(['admin']) && (
  <Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/admin/agencies">
    <i className="fas fa-building mr-2"></i>Manage Agencies
  </Link>
)}

{hasPermission(['admin']) && (
  <Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/admin/plans">
    <i className="fas fa-building mr-2"></i>Manage Plans
  </Link>
)}






            {hasPermission(['owner']) && (
            <Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/Accounts">
              <i className="fas fa-tasks mr-2"></i>Accounts
            </Link>
            )}

{hasPermission(['agency']) && (
  <Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/agency/dashboard">
    <i className="fas fa-tachometer-alt mr-2"></i>Dashboard
  </Link>
)}


            {hasPermission(['agency']) && (

            <Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="agency/accounts">
              <i className="fas fa-tasks mr-2"></i>Manage Businesses
            </Link>

            )}

{hasPermission(['agency']) && (
  <Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/agency/plans">
    <i className="fas fa-file-invoice-dollar mr-2"></i>Manage Plans
  </Link>
)}


          </nav>

          
          

          <div className="flex items-center">

          {hasPermission(['owner', 'staff']) && (
            <span className="text-white mr-4">{businessName}</span>
          )}

{hasPermission(['owner', 'staff',]) && (

            <div className="flex items-center text-white mr-4">
              <svg className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              <span>{username}</span>

            </div>

)}

          

            {hasPermission(['owner', 'staff', 'agency']) && (

            <Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/settings">
            <i className="fas fa-tasks mr-2"></i>Settings
            </Link>


            )}

{hasPermission(['admin']) && (

<Link className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="admin/settings">
<i className="fas fa-tasks mr-2"></i>Settings
</Link>


)}


            <button onClick={logout} className="text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium ml-4">
              <i className="fas fa-sign-out-alt mr-2"></i>Logout
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
