import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config';
import ActivityForm from './ActivityForm';

const ActivityCard = ({ cardId, businessId, contactId, userId, onActivityAdded }) => {
  const [activities, setActivities] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingActivity, setEditingActivity] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);


  useEffect(() => {
    fetchActivities();
  }, [cardId]);




  const fetchActivities = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/activities?filters[card][id][$eq]=${cardId}&sort=datetime:desc&populate=*`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      console.log('Activities response:', response);
      setActivities(response.data.data || []);
    } catch (error) {
      console.error('Error fetching activities:', error);
      setActivities([]);
    }
  };

  const handleFormOpen = (activity = null) => {
    setEditingActivity(activity);
    setIsFormOpen(true);
  };

  const handleFormClose = () => {
    setIsFormOpen(false);
    setEditingActivity(null);
  };

const handleFormSave = async (activityData) => {
  if (!activityData.content.trim()) {
    alert('Activity content cannot be empty');
    return;
  }

  try {
    const payload = {
      data: {
        ...activityData,
        card: cardId,
        business: businessId,
        contact: contactId,
        users_permissions_user: userId,
        datetime: new Date()
      }
    };

    let response;
    if (editingActivity) {
      response = await axios.put(
        `${config.API_URL}/api/activities/${editingActivity.id}`,
        payload,
        { headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` } }
      );
    } else {
      response = await axios.post(
        `${config.API_URL}/api/activities`,
        payload,
        { headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` } }
      );
    }

    console.log('Activity save response:', response);
    fetchActivities();
    handleFormClose();
    onActivityAdded();
  } catch (error) {
    console.error('Error saving activity:', error);
  }
};

  const handleDeleteClick = (activityId) => {
    setConfirmDelete(activityId);
  };

  const handleConfirmDelete = async () => {
    if (confirmDelete) {
      try {
        const response = await axios.delete(`${config.API_URL}/api/activities/${confirmDelete}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
        console.log('Activity delete response:', response);
        setActivities(activities.filter(activity => activity.id !== confirmDelete));
      } catch (error) {
        console.error('Error deleting activity:', error);
      }
      setConfirmDelete(null);
    }
  };

  const ActivityItem = ({ activity }) => (
    <div className="mb-4 p-4 bg-gray-100 rounded relative">
      <p className="text-sm text-gray-600">{new Date(activity.attributes.datetime).toLocaleString()}</p>
      <div dangerouslySetInnerHTML={{ __html: activity.attributes.content }} />
      <div className="absolute top-2 right-2 flex">
        <button onClick={() => handleFormOpen(activity)} className="text-indigo-500 hover:text-indigo-700 mr-2">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
        </svg>
      </button>
      <button onClick={() => handleDeleteClick(activity.id)} className="text-red-500 hover:text-red-700">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
        </svg>
      </button>
      </div>
    </div>
  );

  

  return (
    <div className="mt-8 relative">
      <button onClick={() => handleFormOpen()} className="mb-4 px-4 py-2 bg-indigo-500 text-white rounded">
        Add New Activity
      </button>

      {activities.map((activity) => (
        <ActivityItem key={activity.id} activity={activity} />
      ))}

      <ActivityForm
        isOpen={isFormOpen}
        onClose={handleFormClose}
        activity={editingActivity}
        onSave={handleFormSave}
        isCreating={!editingActivity}
      />

      {confirmDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <p>Are you sure you want to delete this activity?</p>
            <div className="mt-4 flex justify-end">
              <button onClick={() => setConfirmDelete(null)} className="mr-2 px-4 py-2 bg-gray-200 rounded">Cancel</button>
              <button onClick={handleConfirmDelete} className="px-4 py-2 bg-red-500 text-white rounded">Delete</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityCard;
