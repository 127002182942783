import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config';

const Settings = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  useEffect(() => {
    console.log('Settings component mounted');
    fetchUserData();
  }, []);
  
  

  const fetchUserData = async () => {
    try {
      console.log('Fetching user data...');
      const response = await axios.get(`${config.API_URL}/api/users/me`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      console.log('User data received:', response.data);
      setFormData({
        id: response.data.id,
        username: response.data.username,
        email: response.data.email
      });
      console.log('Form data set:', {
        username: response.data.username,
        email: response.data.email
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to load user data');
      setLoading(false);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.username.trim().length < 3) {
      setError('Username must be at least 3 characters long');
      return;
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError('Please enter a valid email address');
      return;
    }
  
    if (formData.password) {
      if (formData.password.length < 6) {
        setError('Password must be at least 6 characters long');
        return;
      }
      if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match');
        return;
      }
    }
  
    setIsSubmitting(true);
  try {
    const dataToUpdate = {
      username: formData.username,
      email: formData.email
    };
    if (formData.password) {
      dataToUpdate.password = formData.password;
    }
    const response = await axios.put(
      `${config.API_URL}/api/users/${formData.id}`,
      dataToUpdate,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      }
    );
    setSuccess('Profile updated successfully');
    setShowSuccessToast(true);
    setTimeout(() => setShowSuccessToast(false), 3000);
    setError('');
    setFormData(prevData => ({ ...prevData, password: '', confirmPassword: '' }));
  } catch (error) {
    console.log('Full error response:', error.response);

    setError(`Failed to update profile: ${error.response?.data?.message || error.message}`);
  } finally {
    setIsSubmitting(false);
  }
};
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Account Settings</h1>
      <form onSubmit={handleSubmit} className="max-w-md">

        <div className="mb-4">
          <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">
            Username
          </label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
  <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
    New Password
  </label>
  <input
    type="password"
    id="password"
    name="password"
    value={formData.password}
    onChange={handleInputChange}
    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
  />
</div>
<div className="mb-4">
  <label htmlFor="confirmPassword" className="block text-gray-700 text-sm font-bold mb-2">
    Confirm New Password
  </label>
  <input
    type="password"
    id="confirmPassword"
    name="confirmPassword"
    value={formData.confirmPassword}
    onChange={handleInputChange}
    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
  />
</div>


        {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
        
        <button
          type="submit"
          className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          disabled={isSubmitting}
          >
          {isSubmitting ? 'Updating...' : 'Update Profile'}
        </button>
      </form>
    
      {showSuccessToast && (
        <div className="fixed bottom-5 right-5 bg-green-500 text-white px-4 py-2 rounded-md">
          {success}
        </div>
      )}
    </div>
  );
};

export default Settings;
