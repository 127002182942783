import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config';
import { Link } from 'react-router-dom';

const Pipelines = () => {
  const [pipelines, setPipelines] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [editingPipeline, setEditingPipeline] = useState(null);
  const [pipelineData, setPipelineData] = useState({ name: '', description: '', card_label: '' });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
const [pipelineToDelete, setPipelineToDelete] = useState(null);
const [showSuccessToast, setShowSuccessToast] = useState(false);
const [successMessage, setSuccessMessage] = useState('');
const [confirmationInput, setConfirmationInput] = useState('');



  useEffect(() => {
    fetchPipelines();
  }, []);

  const fetchPipelines = async () => {
    try {
      const businessId = localStorage.getItem('businessId');
      const response = await axios.get(`${config.API_URL}/api/pipelines?filters[business][id][$eq]=${businessId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      setPipelines(response.data.data);
    } catch (error) {
      console.error('Error fetching pipelines:', error);
    }
  };
  

  const handleCreatePipeline = () => {
    setEditingPipeline(null);
    setPipelineData({ name: '', description: '', card_label: '' });
    setSidebarOpen(true);
  };

  const handleEditPipeline = (pipeline) => {
    setEditingPipeline(pipeline);
    setPipelineData({
      name: pipeline.attributes.name,
      description: pipeline.attributes.description,
      card_label: pipeline.attributes.card_label
    });
    setSidebarOpen(true);
  };

  const handleViewPipeline = (id) => {
    // Implement view pipeline logic
  };

  const handleDeletePipeline = (id) => {
    setPipelineToDelete(id);
    setConfirmationInput('');
    setShowConfirmModal(true);
  };
  

  const handleConfirmationInputChange = (e) => {
    setConfirmationInput(e.target.value);
  };

  
  const performDelete = async () => {
    if (confirmationInput !== 'Apply Destructive Action') {
      return;
    }
    try {

          // Fetch and delete all cards associated with the pipeline
    const cardsResponse = await axios.get(`${config.API_URL}/api/cards?filters[pipeline][id][$eq]=${pipelineToDelete}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
    await Promise.all(cardsResponse.data.data.map(card => 
      axios.delete(`${config.API_URL}/api/cards/${card.id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      })
    ));





      // Delete associated stages
      const stagesResponse = await axios.get(`${config.API_URL}/api/stages?filters[pipeline][id][$eq]=${pipelineToDelete}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      const stagesToDelete = stagesResponse.data.data;
      await Promise.all(stagesToDelete.map(stage => 
        axios.delete(`${config.API_URL}/api/stages/${stage.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        })
      ));
  
      // Delete the pipeline
      await axios.delete(`${config.API_URL}/api/pipelines/${pipelineToDelete}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      setPipelines(prevPipelines => prevPipelines.filter(pipeline => pipeline.id !== pipelineToDelete));
      setShowConfirmModal(false);
      setSuccessMessage('Pipeline and associated stages deleted successfully');
      setShowSuccessToast(true);
      setTimeout(() => setShowSuccessToast(false), 3000);
    } catch (error) {
      console.error('Error deleting pipeline:', error);
    }
  };
  
  
  

  const handleSavePipeline = async () => {
    try {
      if (editingPipeline) {
        await axios.put(`${config.API_URL}/api/pipelines/${editingPipeline.id}`, {
          data: pipelineData
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
        setSuccessMessage('Pipeline updated successfully');
      } else {
        await axios.post(`${config.API_URL}/api/pipelines`, {
          data: {
            ...pipelineData,
            business: localStorage.getItem('businessId')
          }
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
        setSuccessMessage('New pipeline created successfully');
      }
      setSidebarOpen(false);
      fetchPipelines();
      setShowSuccessToast(true);
      setTimeout(() => setShowSuccessToast(false), 3000);
    } catch (error) {
      console.error('Error saving pipeline:', error);
    }
  };
  

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-indigo-600">Pipelines</h1>
        <button
          onClick={handleCreatePipeline}
          className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
        >
          Create New Pipeline
        </button>
      </div>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
  {pipelines.map((pipeline) => (
    <div key={pipeline.id} className="bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1">
      <div className="p-6 flex flex-col h-full">
        <div className="flex-grow">
          <h2 className="text-xl font-bold text-gray-800 mb-2 truncate">{pipeline.attributes.name}</h2>
          <p className="text-sm text-gray-600 mb-4 line-clamp-3">{pipeline.attributes.description}</p>
        </div>
        <div className="flex justify-end space-x-2 pt-4 border-t border-gray-200">
          <button
            onClick={() => handleEditPipeline(pipeline)}
            className="text-blue-600 hover:text-blue-800 font-medium transition-colors duration-200"
          >
            Edit
          </button>
          <Link
  to={`/pipelines/${pipeline.id}`}
  className="text-green-600 hover:text-green-800 font-medium transition-colors duration-200"
>
  View
</Link>
          <button
            onClick={() => handleDeletePipeline(pipeline.id)}
            className="text-red-600 hover:text-red-800 font-medium transition-colors duration-200"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  ))}
</div>


      {sidebarOpen && (
        <div className="fixed inset-0 overflow-hidden z-50">
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={() => setSidebarOpen(false)}></div>
            <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-white shadow-xl">
                  <div className="flex-1 h-0 overflow-y-auto">
                    <header className="px-4 py-6 bg-gray-50 sm:px-6">
                      <h2 className="text-lg font-medium text-gray-900">
                        {editingPipeline ? 'Edit Pipeline' : 'Create New Pipeline'}
                      </h2>
                    </header>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="px-4 divide-y divide-gray-200 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <div>
                            <label htmlFor="pipeline-name" className="block text-sm font-medium text-gray-700">Name</label>
                            <input
                              type="text"
                              name="pipeline-name"
                              id="pipeline-name"
                              value={pipelineData.name}
                              onChange={(e) => setPipelineData({...pipelineData, name: e.target.value})}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div>
                            <label htmlFor="pipeline-description" className="block text-sm font-medium text-gray-700">Description</label>
                            <textarea
                              name="pipeline-description"
                              id="pipeline-description"
                              value={pipelineData.description}
                              onChange={(e) => setPipelineData({...pipelineData, description: e.target.value})}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            ></textarea>
                          </div>
                          <div>
                            <label htmlFor="pipeline-card-label" className="block text-sm font-medium text-gray-700">Card Label</label>
                            <input
                              type="text"
                              name="pipeline-card-label"
                              id="pipeline-card-label"
                              value={pipelineData.card_label}
                              onChange={(e) => setPipelineData({...pipelineData, card_label: e.target.value})}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setSidebarOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={handleSavePipeline}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}


{showConfirmModal && (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      <div className="mt-3 text-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Confirm Deletion</h3>
        <div className="mt-2 px-7 py-3">
          <p className="text-sm text-gray-500">
            Warning: This action will delete the pipeline and all associated cards and stages. This cannot be undone.
          </p>
          <p className="text-sm text-gray-500 mt-2">
            Type "Apply Destructive Action" to confirm:
          </p>
          <input
            type="text"
            value={confirmationInput}
            onChange={handleConfirmationInputChange}
            className="mt-2 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div className="items-center px-4 py-3">
          <button
            id="ok-btn"
            className={`px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full ${confirmationInput !== 'Apply Destructive Action' ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={performDelete}
            disabled={confirmationInput !== 'Apply Destructive Action'}
          >
            Delete All Pipeline Data
          </button>
          <button
            id="cancel-btn"
            className="mt-2 px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full"
            onClick={() => setShowConfirmModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
)}


{showSuccessToast && (
  <div className="fixed bottom-5 right-5 bg-green-500 text-white px-4 py-2 rounded-md">
    {successMessage}
  </div>
)}






    </div>
  );
};

export default Pipelines;
