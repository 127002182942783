import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';

const AgencyPlanForm = ({ isOpen, onClose, onSave, editingPlan, agencyId }) => {
  const [formData, setFormData] = useState({
    name: '',
    price: 0,
    renewalPeriodDays: 30,
    usersAllowed: 1,
    description: '',
    isActive: true,
    agency: agencyId
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (editingPlan) {
      setFormData({
        name: editingPlan.attributes.name,
        price: editingPlan.attributes.price,
        renewalPeriodDays: editingPlan.attributes.renewalPeriodDays,
        usersAllowed: editingPlan.attributes.usersAllowed,
        description: editingPlan.attributes.description,
        isActive: editingPlan.attributes.isActive,
        agency: agencyId
      });
    } else {
      setFormData({
        name: '',
        price: 0,
        renewalPeriodDays: 30,
        usersAllowed: 1,
        description: '',
        isActive: true,
        agency: agencyId
      });
    }
  }, [editingPlan, agencyId]);

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      let response;
      const dataToSend = { ...formData, agency: agencyId };
      if (editingPlan) {
        response = await axios.put(`${config.API_URL}/api/agency-plans/${editingPlan.id}`, {
          data: dataToSend
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
      } else {
        response = await axios.post(`${config.API_URL}/api/agency-plans`, {
          data: dataToSend
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
      }
      
      onSave(response.data.data);
      onClose();
    } catch (error) {
      console.error('Error saving plan:', error);
      setError(error.response?.data?.error?.message || 'An error occurred while saving');
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto p-6">
          <h2 className="text-indigo-600 text-2xl mb-4">{editingPlan ? 'Edit Plan' : 'Add New Plan'}</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Plan Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Price</label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Renewal Period (Days)</label>
              <input
                type="number"
                name="renewalPeriodDays"
                value={formData.renewalPeriodDays}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Users Allowed</label>
              <input
                type="number"
                name="usersAllowed"
                value={formData.usersAllowed}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                rows="3"
              ></textarea>
            </div>
            <div>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="isActive"
                  checked={formData.isActive}
                  onChange={handleChange}
                  className="form-checkbox h-5 w-5 text-indigo-600"
                />
                <span className="ml-2 text-gray-700">Active</span>
              </label>
            </div>
          </div>
        </div>
        {error && <p className="text-red-500 text-xs italic mb-4 px-6">{error}</p>}
        <div className="flex justify-end p-4 border-t">
          <button onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-800 mr-2">
            Cancel
          </button>
          <button 
            onClick={handleSubmit} 
            className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600"
          >
            {editingPlan ? 'Update' : 'Create'}
          </button>
        </div>
      </div>
    </div>
  );
};  

export default AgencyPlanForm;
