import React, { useState } from 'react';

const MoveCardsModal = ({ stages, onMove, onCancel }) => {
  const [selectedStage, setSelectedStage] = useState('');

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg shadow-xl">
        <h2 className="text-red text-xl font-bold mb-4">Move Cards to Another Stage Before Deleting!</h2>
        <select
          value={selectedStage}
          onChange={(e) => setSelectedStage(e.target.value)}
          className="w-full p-2 mb-4 border rounded"
        >
          <option value="">Select a stage</option>
          {stages.map(stage => (
            <option key={stage.id} value={stage.id}>{stage.attributes.name}</option>
          ))}
        </select>
        <div className="flex justify-end">
          <button 
            onClick={onCancel}
            className="mr-2 px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button 
            onClick={() => onMove(selectedStage)}
            disabled={!selectedStage}
            className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 disabled:bg-gray-400"
          >
            Move and Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default MoveCardsModal;
