import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ logout, isCollapsed, setIsCollapsed }) => {
  return (
<div className={`flex-shrink-0 flex flex-col h-screen bg-indigo-600 border-r border-indigo-700 transition-all duration-300 ${isCollapsed ? 'w-16' : 'w-64'}`}>
<button
  onClick={() => setIsCollapsed(!isCollapsed)}
  className="p-2 text-white hover:bg-indigo-700 self-end rounded-full focus:outline-none"
>
  <svg
    className="w-6 h-6 transition-transform duration-300"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)' }}
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
  </svg>
</button>




  <div className="flex flex-col justify-between flex-1 mt-6">
        <nav>
        <Link className="flex items-center px-4 py-2 text-white hover:bg-indigo-700 rounded-md" to="/dashboard">
            <i className="fas fa-tachometer-alt"></i>
            {!isCollapsed && <span className="mx-4 font-medium">Dashboard</span>}
          </Link>
          <Link className="flex items-center px-4 py-2 mt-4 text-white hover:bg-indigo-700 rounded-md" to="/pipelines">
            <i className="fas fa-project-diagram"></i>
            {!isCollapsed && <span className="mx-4 font-medium">Pipelines</span>}
          </Link>
          <Link className="flex items-center px-4 py-2 mt-4 text-white hover:bg-indigo-700 rounded-md" to="/contacts">
            <i className="fas fa-project-diagram"></i>
            {!isCollapsed && <span className="mx-4 font-medium">Contacts</span>}
          </Link>
          <Link className="flex items-center px-4 py-2 mt-5 text-white hover:bg-indigo-700 rounded-md" to="/Products">
            <i className="fas fa-file-alt"></i>
            {!isCollapsed && <span className="mx-4 font-medium">Products</span>}
          </Link>
          <Link className="flex items-center px-4 py-2 mt-5 text-white hover:bg-indigo-700 rounded-md" to="/companies">
            <i className="fas fa-calendar-alt"></i>
            {!isCollapsed && <span className="mx-4 font-medium">Companies</span>}
          </Link>
          <Link className="flex items-center px-4 py-2 mt-5 text-white hover:bg-indigo-700 rounded-md" to="/activities">
            <i className="fas fa-file-alt"></i>
            {!isCollapsed && <span className="mx-4 font-medium">Activities</span>}
          </Link>
          <Link className="flex items-center px-4 py-2 mt-5 text-white hover:bg-indigo-700 rounded-md" to="/settings">
            <i className="fas fa-chart-line"></i>
            {!isCollapsed && <span className="mx-4 font-medium">Settings</span>}
          </Link>
          <div onClick={logout} className="flex items-center px-4 py-2 mt-5 text-white hover:bg-indigo-700 rounded-md cursor-pointer">
            <i className="fas fa-sign-out-alt"></i>
            {!isCollapsed && <span className="mx-4 font-medium">Logout</span>}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
