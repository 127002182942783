import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';
import { useBusinessContext } from '../../context/BusinessContext';

const BusinessAccountForm = ({ isOpen, onClose, onSave, editingBusiness, adminId }) => {
  const [formData, setFormData] = useState({
    name: '',
    ownerName: '',
    ownerEmail: '',
    ownerPassword: '',
    confirmPassword: '',
    plan: '',
    price_paid: 0,
    isActive: true
  });
  const [error, setError] = useState('');
  const { agencyId } = useBusinessContext();
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    fetchPlans();
  }, []);

  const resetFormData = () => {
  setFormData({
    name: '',
    ownerName: '',
    ownerEmail: '',
    ownerPassword: '',
    confirmPassword: '',
    plan: '',
    price_paid: 0,
    isActive: true
  });
};

  useEffect(() => {
    if (editingBusiness) {
      setFormData({
        name: editingBusiness.attributes.name,
        ownerName: editingBusiness.attributes.owner?.data?.attributes?.username || '',
        ownerEmail: editingBusiness.attributes.owner?.data?.attributes?.email || '',
        ownerPassword: '',
        confirmPassword: '',
        plan: editingBusiness.attributes.plan?.data?.id || '',
        price_paid: editingBusiness.attributes.price_paid || 0,
        isActive: editingBusiness.attributes.isActive !== undefined ? editingBusiness.attributes.isActive : true
      });
    } else {
      resetFormData();
    }
  }, [editingBusiness]);

  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/agency-plans?filters[agency][id][$eq]=${agencyId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      console.log('Fetched plans:', response.data.data);
      setPlans(response.data.data);
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePlanChange = (e) => {
    const selectedPlanId = parseInt(e.target.value, 10);
    const selectedPlan = plans.find(plan => plan.id === selectedPlanId);
    console.log('Selected plan:', selectedPlan);
    console.log('New price:', selectedPlan ? selectedPlan.attributes.price : 0);
    setFormData({
      ...formData,
      plan: selectedPlanId,
      price_paid: selectedPlan ? selectedPlan.attributes.price : 0
    });
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  setError('');

  if (formData.ownerPassword !== formData.confirmPassword) {
    setError('Passwords do not match');
    return;
  }

  try {
    let response;
    if (editingBusiness) {
      response = await axios.put(`${config.API_URL}/api/businesses/${editingBusiness.id}`, {
        data: {
          name: formData.name,
          plan: formData.plan,
          price_paid: formData.price_paid
        }
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
    } else {
      response = await axios.post(`${config.API_URL}/api/businesses`, {
        data: {
          name: formData.name,
          agency: { connect: [{ id: agencyId }] },
          plan: formData.plan,
          price_paid: formData.price_paid,
          admin: adminId
        }
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });

      await axios.post(`${config.API_URL}/api/users`, {
        username: formData.ownerName,
        email: formData.ownerEmail,
        password: formData.ownerPassword,
        role: 7,
        business: response.data.data.id,
        agency: agencyId,
        admin: adminId
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });

      // Create new business subscription
      const selectedPlan = plans.find(plan => plan.id === formData.plan);
      const startDate = new Date();
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + selectedPlan.attributes.renewalPeriodDays);

      await axios.post(`${config.API_URL}/api/business-subscriptions`, {
        data: {
          business: response.data.data.id,
          agency: agencyId,
          agency_plan: formData.plan,
          startDate: startDate,
          endDate: endDate,
          isActive: true,
          price_paid: parseFloat(formData.price_paid)
        }
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
    }

    const completeBusinessData = await axios.get(`${config.API_URL}/api/businesses/${response.data.data.id}?populate=owner,plan`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });

    onSave(completeBusinessData.data.data);
    resetFormData();
    onClose();
  } catch (error) {
    console.error('Error saving business:', error);
    setError(error.response?.data?.error?.message || 'An error occurred while saving');
  }
};

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto p-6">
          <h2 className="text-indigo-600 text-2xl mb-4">{editingBusiness ? 'Edit Business' : 'Add New Business'}</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Business Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>
            {!editingBusiness && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Owner Name</label>
                  <input
                    type="text"
                    name="ownerName"
                    value={formData.ownerName}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Owner Email</label>
                  <input
                    type="email"
                    name="ownerEmail"
                    value={formData.ownerEmail}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Owner Password</label>
                  <input
                    type="password"
                    name="ownerPassword"
                    value={formData.ownerPassword}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
              </>
            )}
            <div>
              <label className="block text-sm font-medium text-gray-700">Select Plan</label>
              <select
                name="plan"
                value={formData.plan}
                onChange={handlePlanChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              >
                <option value="">Select a plan</option>
                {plans.map((plan) => (
                  <option key={plan.id} value={plan.id}>{plan.attributes.name}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Price</label>
              <input
                type="number"
                name="price_paid"
                value={formData.price_paid}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>

            <div>
  <label className="flex items-center">
    <input
      type="checkbox"
      name="isActive"
      checked={formData.isActive}
      onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
      className="form-checkbox h-5 w-5 text-indigo-600"
    />
    <span className="ml-2 text-gray-700">Active</span>
  </label>
</div>

          </div>
        </div>
        {error && <p className="text-red-500 text-xs italic mb-4 px-6">{error}</p>}
        <div className="flex justify-end p-4 border-t">
          <button onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-800 mr-2">
            Cancel
          </button>
          <button 
            onClick={handleSubmit} 
            className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600"
          >
            {editingBusiness ? 'Update' : 'Create'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessAccountForm;
