import React from 'react';
import { Navigate } from 'react-router-dom';
import { useBusinessContext } from '../context/BusinessContext';

const ProtectedRoute = ({ element: Element, allowedRoles }) => {
  const { userRoles,userType, loading } = useBusinessContext();

  if (loading) {
    return <div>Loading PR...</div>;
  }

  if (!userRoles || !userRoles.type) {
    return <Navigate to="/login" replace />;
  }

  const hasPermission = allowedRoles.includes(userRoles.type);

  return hasPermission ? <Element /> : <Navigate to="/unauthorized" replace />;
};

export default ProtectedRoute;
