import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config';
import { getUsersByBusiness } from './usersApi';
import useOutsideClick from './Hooks';

const Card = ({ isOpen, onClose, onSave, stages, businessId, resetTrigger, pipelineId, editingCard }) => {
  const [cardData, setCardData] = useState({
    name: '',
    description: '',
    contact: null,
    stage: null,
    users_permissions_user: null,
    product: null,
    business: businessId,
    pipeline: pipelineId
  });



useEffect(() => {
  if (editingCard) {
    console.log('Editing card data:', editingCard);
    setCardData({
      name: editingCard.attributes.name,
      description: editingCard.attributes.description,
      contact: editingCard.attributes.contact?.data,
      stage: editingCard.attributes.stage?.data?.id,
      users_permissions_user: editingCard.attributes.users_permissions_user?.data?.id,
      business: businessId,
      pipeline: pipelineId
    });
    setContactSearch(editingCard.attributes.contact?.data?.attributes?.name || '');
    setStageSearch(editingCard.attributes.stage?.data?.attributes?.name || '');
    setUserSearch(editingCard.attributes.users_permissions_user?.data?.attributes?.username || '');
    
    // Set selected items
    setSelectedContact(editingCard.attributes.contact?.data);
    setSelectedStage(stages.find(stage => stage.id === editingCard.attributes.stage?.data?.id));
    setSelectedUser(editingCard.attributes.users_permissions_user?.data);
  } else {
    // Reset form when not editing
    resetForm();
  }
}, [editingCard, businessId, pipelineId, stages]);




  
  const [contacts, setContacts] = useState([]);
  

  const [filteredContacts, setFilteredContacts] = useState([]);
  const [contactSearch, setContactSearch] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [stageSearch, setStageSearch] = useState('');
const [filteredStages, setFilteredStages] = useState(stages || []);
const [isStageDropdownOpen, setIsStageDropdownOpen] = useState(false);
const [selectedStage, setSelectedStage] = useState(null);

const [userSearch, setUserSearch] = useState('');
const [filteredUsers, setFilteredUsers] = useState([]);
const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
const [selectedUser, setSelectedUser] = useState(null);

const [selectedContact, setSelectedContact] = useState(null);

const [products, setProducts] = useState([]);
const [filteredProducts, setFilteredProducts] = useState([]);
const [productSearch, setProductSearch] = useState('');





const closeDropdowns = () => {
  setIsDropdownOpen(false);
  setIsStageDropdownOpen(false);
  setIsUserDropdownOpen(false);
};

const dropdownRef = useOutsideClick(closeDropdowns);

useEffect(() => {
  setFilteredStages(stages);
}, [stages]);



  const handleContactSelect = (contact) => {
    setCardData(prevData => ({
      ...prevData,
      contact: {
        id: contact.id,
        name: contact.attributes.name
      }
    }));
    setContactSearch(contact.attributes.name);
    setSelectedContact(contact);
    setIsDropdownOpen(false);
  };


const handleStageSearch = (e) => {
  const searchTerm = e.target.value.toLowerCase();
  setStageSearch(searchTerm);
  setFilteredStages(
    stages.filter(stage => 
      stage.attributes.name.toLowerCase().includes(searchTerm)
    )
  );
  setIsStageDropdownOpen(true);
};

  
const handleStageSelect = (stage) => {
  setCardData(prevData => ({
    ...prevData,
    stage: stage.id
  }));
  setStageSearch(stage.attributes.name);
  setSelectedStage(stage);
  setIsStageDropdownOpen(false);
};



  useEffect(() => {
    fetchContacts();
  }, []);



const handleUserSearch = (e) => {
  const searchTerm = e.target.value;
  setUserSearch(searchTerm);
  setIsUserDropdownOpen(true);
};


const handleUserSelect = (user) => {
  setCardData(prevData => ({
    ...prevData,
    users_permissions_user: user.id
  }));
  setUserSearch(user.username);
  setSelectedUser(user);
  setIsUserDropdownOpen(false);
};

const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);
const [selectedProduct, setSelectedProduct] = useState(null);

const handleProductSearch = (e) => {
  const searchTerm = e.target.value.toLowerCase();
  setProductSearch(searchTerm);
  setFilteredProducts(
    products.filter(product => 
      product.attributes.name.toLowerCase().includes(searchTerm)
    )
  );
  setIsProductDropdownOpen(true);
};

const handleProductSelect = (product) => {
  handleInputChange({ target: { name: 'product', value: product.id } });
  // setCardData(prevData => ({
  //   ...prevData,
  //   product: product.id
  // }));
  setProductSearch(product.attributes.name);
  setSelectedProduct(product);
  setIsProductDropdownOpen(false);
};



useEffect(() => {
  if (resetTrigger) {
    setCardData(prevData => ({
      ...prevData,
      name: '',
      description: '',
      contact: '',
      stage: '',
    }));
  }
}, [resetTrigger]);




  

  const [formErrors, setFormErrors] = useState({});


  const [users, setUsers] = useState([]);

  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

useEffect(() => {
  const fetchUsers = async () => {
    setIsLoadingUsers(true);
    try {
      const usersData = await getUsersByBusiness(businessId);
      console.log('Fetched users:', usersData);
      setUsers(usersData);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  fetchUsers();
}, [businessId]);


useEffect(() => {
  if (!isLoadingUsers && users.length > 0) {
    const searchTerm = userSearch.toLowerCase();
    setFilteredUsers(users.filter(user => 
      user.username.toLowerCase().includes(searchTerm)
    ));
  }
}, [users, isLoadingUsers, userSearch]);





  const fetchContacts = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/contacts?filters[business][id][$eq]=${businessId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      setContacts(response.data.data);
      setFilteredContacts(response.data.data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const fetchProducts = async () => {
  try {
    const response = await axios.get(`${config.API_URL}/api/products?filters[business][id][$eq]=${businessId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
    console.log('Products API response:', response);
    setProducts(response.data.data);
    setFilteredProducts(response.data.data);
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};

useEffect(() => {
  fetchProducts();
}, []);



  const resetForm = () => {
  setCardData({
    name: '',
    description: '',
    contact: null,
    stage: null,
    users_permissions_user: null,
    product: null,
    business: businessId,
    pipeline: pipelineId
  });
  setContactSearch('');
  setStageSearch('');
  setUserSearch('');
  setProductSearch('');
  setSelectedStage(null);
  setSelectedUser(null);
  setSelectedProduct(null);
  setIsDropdownOpen(false);
  setIsStageDropdownOpen(false);
  setIsUserDropdownOpen(false);
  setIsProductDropdownOpen(false);
};

  

const handleInputChange = (e) => {
  const { name, value } = e.target;
  if (name === 'contact') {
    const selectedContact = contacts.find(contact => contact.id === parseInt(value, 10));
    setCardData(prevData => ({
      ...prevData,
      contact: {
        id: value,
        name: selectedContact ? selectedContact.attributes.name : ''
      }
    }));
  } else if (name === 'users_permissions_user') {
    setCardData(prevData => ({
      ...prevData,
      [name]: value ? parseInt(value, 10) : null
    }));
  } else if (name === 'product') {
    setCardData(prevData => ({
      ...prevData,
      product: value ? parseInt(value, 10) : null
    }));
  } else {
    setCardData(prevData => ({ ...prevData, [name]: value }));
  }
};



  const handleContactSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setContactSearch(searchTerm);
    setFilteredContacts(contacts.filter(contact => 
      contact.attributes.name.toLowerCase().includes(searchTerm) ||
      contact.attributes.email.toLowerCase().includes(searchTerm)
    ));
  };

  const validateForm = () => {
    const errors = {};
    if (!cardData.name) errors.name = "Name is required";
    if (!cardData.contact) errors.contact = "Contact is required";
    if (!cardData.stage) errors.stage = "Stage is required";
    if (!cardData.users_permissions_user) errors.users_permissions_user = "users_permissions_user is required";
    if (!cardData.product) errors.product = "Product is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  

  const handleSave = () => {
    console.log('Attempting to save card:', cardData);
    if (validateForm()) {
      console.log('Form validated, calling onSave');
      console.log('Contact data being saved:', cardData.contact);
      console.log('Product data being saved:', cardData.product);
      onSave(cardData, editingCard?.id);
      resetForm();
      onClose();
    } else {
      console.log('Form validation failed', formErrors);
    }
  };

  console.log('Card component rendered, isOpen:', isOpen);

  

  console.log('Filtered Stages:', filteredStages);

  
  return (
    <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto p-6">
          <h2 className="text-2xl font-bold mb-4">{editingCard ? 'Edit Card' : 'Add New Card'}</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                value={cardData.name}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
              {formErrors.name && <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                name="description"
                value={cardData.description}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                rows="3"
              ></textarea>
            </div>

            <div ref={dropdownRef}>
            <div>

  <label className="block text-sm font-medium text-gray-700">Contact</label>
  <div className="relative mt-1">
    <input
      type="text"
      value={contactSearch}
      onChange={handleContactSearch}
      onClick={() => setIsDropdownOpen(true)}
      className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
      placeholder="Search contacts..."
    />
    {isDropdownOpen && (
      <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
        {filteredContacts.slice(0, 5).map(contact => (
          <li
            key={contact.id}
            onClick={() => handleContactSelect(contact)}
            className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
          >
            {contact.attributes.name} ({contact.attributes.email})
          </li>
        ))}
      </ul>
    )}
  </div>
</div>

<div ref={dropdownRef}>
  <label className="mt-2 block text-sm font-medium text-gray-700">Product</label>
  <div className="relative mt-1">
    <input
      type="text"
      value={productSearch}
      onChange={handleProductSearch}
      onClick={() => setIsProductDropdownOpen(true)}
      className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
      placeholder="Search products..."
    />
    {isProductDropdownOpen && (
      <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
        {filteredProducts.map(product => (
          <li
            key={product.id}
            onClick={() => handleProductSelect(product)}
            className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
          >
            {product.attributes.name}
          </li>
        ))}
      </ul>
    )}
  </div>
</div>


<div ref={dropdownRef}>
  <label className="block text-sm font-medium text-gray-700">Stage</label>
  <div className="relative mt-1">
    <input
      type="text"
      value={stageSearch}
      onChange={handleStageSearch}
      onClick={() => setIsStageDropdownOpen(true)}
      className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
      placeholder="Search stages..."
    />
    {isStageDropdownOpen && (
      <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
        {filteredStages.map(stage => (
          <li
            key={stage.id}
            onClick={() => handleStageSelect(stage)}
            className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
          >
            {stage.attributes.name}
          </li>
        ))}
      </ul>
    )}
  </div>
</div>


<div ref={dropdownRef}> 
  <label className="block text-sm font-medium text-gray-700">Assign User</label>
  <div className="relative mt-1">
    <input
      type="text"
      value={userSearch}
      onChange={handleUserSearch}
      onClick={() => setIsUserDropdownOpen(true)}
      className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
      placeholder="Search users..."
    />
    {isUserDropdownOpen && (
      <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
        {filteredUsers.map(user => (
          <li
            key={user.id}
            onClick={() => handleUserSelect(user)}
            className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
          >
            {user.username}
          </li>
        ))}
      </ul>
    )}
  </div>
</div>
</div>






          </div>
        </div>
        <div className="flex justify-end p-4 border-t">
          <button onClick={() => { onClose(); resetForm(); }} className="px-4 py-2 text-gray-600 hover:text-gray-800 mr-2">
            Cancel
          </button>
          <button onClick={handleSave} className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600">
          {editingCard ? 'Update' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
