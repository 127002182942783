import axios from 'axios';
import config from '../config/config';

export const getUsersByBusiness = async (businessId) => {
  try {
    console.log('Fetching users for business ID:', businessId);
    const response = await axios.get(`${config.API_URL}/api/users?filters[business][id][$eq]=${businessId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
    console.log('Users API response:', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};
