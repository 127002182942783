import axios from 'axios';
import config from '../config/config';

const API_URL = `${config.API_URL}/api/schedules`;

export const scheduleApi = {
  // Fetch all schedules for a specific card
  getSchedules: async (cardId) => {
    try {
      const response = await axios.get(`${API_URL}?filters[card][id][$eq]=${cardId}&populate=*`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching schedules:', error);
      throw error;
    }
  },



  // Create a new schedule
  createSchedule: async (scheduleData) => {
    try {
      // CHANGE: Simplify the payload structure
      const payload = {
        data: scheduleData
      };
  
      console.log('Full payload being sent to server:', JSON.stringify(payload, null, 2));
  
      const response = await axios.post(API_URL, payload, {
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
          'Content-Type': 'application/json'
        }
      });
      return response.data.data;
    } catch (error) {
      console.error('Error creating schedule:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      throw error;
    }
  },

  // Update an existing schedule
  updateSchedule: async (id, scheduleData) => {
    try {
      const response = await axios.put(`${API_URL}/${id}`, { data: scheduleData }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      return response.data.data;
    } catch (error) {
      console.error('Error updating schedule:', error);
      throw error;
    }
  },

  // Delete a schedule
  deleteSchedule: async (id) => {
    try {
      await axios.delete(`${API_URL}/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
    } catch (error) {
      console.error('Error deleting schedule:', error);
      throw error;
    }
  },

  // Get a single schedule by ID
  getScheduleById: async (id) => {
    try {
      const response = await axios.get(`${API_URL}/${id}?populate=*`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching schedule:', error);
      throw error;
    }
  }
};

export default scheduleApi;