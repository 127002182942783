import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // Import useNavigate hook
import config from '../config/config'; // Adjust the path based on your project structure

const Registration = ({ setAuth }) => {  // Accept setAuth as a prop
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();  // Initialize the navigate hook

  const handleRegister = async () => {
    try {
      const response = await axios.post(`${config.API_URL}/api/auth/local/register`, {
        username,
        email,
        password
      });
      console.log('Registration Success:', response.data);
      localStorage.setItem('token', response.data.jwt); // Assuming the token is in response.data.jwt
      setAuth(true); // Update authentication state
      navigate('/');  // Redirect to home page
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
        setError(error.response.data.error.message);
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8 bg-white shadow-md rounded">
        <h1 className="text-xl font-bold mb-4">Register</h1>
        {error && <p className="text-red-500">{error}</p>}
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="w-full p-2 mb-2 border rounded"
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 mb-2 border rounded"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full p-2 mb-4 border rounded"
        />
        <button onClick={handleRegister} className="w-full p-2 bg-blue-500 text-white rounded">
          Register
        </button>
      </div>
    </div>
  );
};

export default Registration;
