import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const ActivityForm = ({ isOpen, onClose, activity, onSave, isCreating }) => {
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
  if (isOpen) {
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }
}, [isOpen]);



  useEffect(() => {
    if (activity && !isCreating) {
      setContent(activity.attributes.content);
    } else {
      setContent('');
    }
  }, [activity, isCreating]);

  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ content, id: activity ? activity.id : null });
  };

  useEffect(() => {
  if (isOpen) {
    // Simulate loading time for TinyMCE
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }
}, [isOpen]);


  if (!isOpen) return null;

  if (isLoading) {
  return (
    <div className="fixed inset-y-0 right-0 w-96 bg-white shadow-xl flex items-center justify-center">
      <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
    </div>
  );
}



  return (
    <div className="fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform transition-transform duration-300 ease-in-out">
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto p-6">
          <h2 className="text-2xl font-bold mb-4">{isCreating ? 'Create Activity' : 'Edit Activity'}</h2>
          <form onSubmit={handleSubmit}>
            <Editor
                apiKey='7tl2t9w4q4505fwlq6tckbx1ouh5pyc0hkse2polbhmdu2o4'
              value={content}
              onEditorChange={handleEditorChange}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help'
              }}
            />
            <div className="flex justify-end mt-4">
              <button type="button" onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-800 mr-2">
                Cancel
              </button>
              <button type="submit" className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-blue-600">
                {isCreating ? 'Create' : 'Save'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ActivityForm;
