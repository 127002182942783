import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config';

const ProductForm = ({ isOpen, onClose, onSave, editingProduct }) => {
  const [productData, setProductData] = useState({
    name: '',
    description: '',
    price: '',
    productcode: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editingProduct) {
      setProductData({
        name: editingProduct.attributes.name,
        description: editingProduct.attributes.description,
        price: editingProduct.attributes.price,
        productcode: editingProduct.attributes.productcode
      });
    } else {
      setProductData({
        name: '',
        description: '',
        price: '',
        productcode: ''
      });
    }
  }, [editingProduct]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData(prevData => ({ ...prevData, [name]: value }));
  };


const handleSave = async () => {
  setIsLoading(true);
  try {
    let response;
    const businessId = localStorage.getItem('businessId');
    if (editingProduct) {
      response = await axios.put(`${config.API_URL}/api/products/${editingProduct.id}`, {
        data: productData
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
    } else {
      response = await axios.post(`${config.API_URL}/api/products`, {
        data: {
          ...productData,
          business: businessId
        }
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
    }
    console.log('Full product save response:', response);
    onSave(response.data.data);
  } catch (error) {
    console.error('Error saving product:', error);
  } finally {
    setIsLoading(false);
  }
};





  if (isLoading) {
    return (
      <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl flex items-center justify-center transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
        <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto p-6">
          <h2 className="text-indigo-600 text-2xl mb-4">{editingProduct ? 'Edit Product' : 'Add New Product'}</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                value={productData.name}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                name="description"
                value={productData.description}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                rows="3"
              ></textarea>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Price</label>
              <input
                type="number"
                name="price"
                value={productData.price}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Product Code</label>
              <input
                type="text"
                name="productcode"
                value={productData.productcode}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end p-4 border-t">
          <button onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-800 mr-2">
            Cancel
          </button>
          <button 
            onClick={handleSave} 
            className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600"
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;
