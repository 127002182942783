import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config';

const ContactForm = ({ isOpen, onClose, onSave, editingContact }) => {
  const [contactData, setContactData] = useState({
    name: '',
    email: '',
    phonenumber: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editingContact) {
      setContactData({
        name: editingContact.attributes.name,
        email: editingContact.attributes.email,
        phonenumber: editingContact.attributes.phonenumber
      });
    } else {
      setContactData({
        name: '',
        email: '',
        phonenumber: ''
      });
    }
  }, [editingContact]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      let response;
      const businessId = localStorage.getItem('businessId');
      if (editingContact) {
        response = await axios.put(`${config.API_URL}/api/contacts/${editingContact.id}`, {
          data: contactData
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
      } else {
        response = await axios.post(`${config.API_URL}/api/contacts`, {
          data: {
            ...contactData,
            business: businessId
          }
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
      }
      onSave(response.data.data);
    } catch (error) {
      console.error('Error saving contact:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto p-6">
          <h2 className="text-indigo-600 text-2xl mb-4">{editingContact ? 'Edit Contact' : 'Add New Contact'}</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                value={contactData.name}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={contactData.email}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Phone Number</label>
              <input
                type="tel"
                name="phonenumber"
                value={contactData.phonenumber}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end p-4 border-t">
          <button onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-800 mr-2">
            Cancel
          </button>
          <button 
            onClick={handleSave} 
            className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600"
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

