import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import ScheduleApi from './ScheduleApi';

const ScheduleList = forwardRef(({ cardId, onCreateNew, onEditItem }, ref) => {
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSchedules();
  }, [cardId]);

const fetchSchedules = async () => {
  try {
    setLoading(true);
    const data = await ScheduleApi.getSchedules(cardId);
    // Sort schedules by start_time in descending order
    const sortedSchedules = data.sort((a, b) => 
      new Date(b.attributes.start_time) - new Date(a.attributes.start_time)
    );
    setSchedules(sortedSchedules);
    setLoading(false);
  } catch (err) {
    setError('Failed to fetch schedules');
    setLoading(false);
  }
};

  // Expose fetchSchedules through a ref
  useImperativeHandle(ref, () => ({
    fetchSchedules
  }));




  const handleCreateNew = () => {
    onCreateNew();
  };

  const handleEdit = (schedule) => {
    onEditItem(schedule);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this schedule?')) {
      try {
        await ScheduleApi.deleteSchedule(id);
        await fetchSchedules(); // Refresh the list
      } catch (err) {
        setError('Failed to delete schedule');
      }
    }
  };

  if (loading) return <div>Loading schedules...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="schedule-list">
      <h2 className="text-2xl font-bold mb-4">Schedules</h2>
      <button 
        onClick={handleCreateNew}
        className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded mb-4"
      >
        Create New Schedule
      </button>
      {schedules.length === 0 ? (
        <p>No schedules found.</p>
      ) : (
        <ul className="space-y-4">
          {[...schedules].sort((a, b) => 
      new Date(b.attributes.start_time) - new Date(a.attributes.start_time)
    ).map((schedule) => (
            <li key={schedule.id} className="border p-4 rounded shadow">
              <h3 className="font-bold">{schedule.attributes.title}</h3>
              <p>{new Date(schedule.attributes.start_time).toLocaleString()}</p>
              <p>{schedule.attributes.type}</p>
              <div className="mt-2">
                <button 
                  onClick={() => handleEdit(schedule)}
                  className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2"
                >
                  Edit
                </button>
                <button 
                  onClick={() => handleDelete(schedule.id)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});
export default ScheduleList