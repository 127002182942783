import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';
import { useBusinessContext } from '../../context/BusinessContext';

const AgencyAccountForm = ({ isOpen, onClose, onSave, editingAgency }) => {
  const [formData, setFormData] = useState({
    name: '',
    ownerName: '',
    ownerEmail: '',
    ownerPassword: '',
    confirmPassword: '',
    price_paid: 0,
    isActive: true
  });
  const [error, setError] = useState('');
  const { adminId } = useBusinessContext();

  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/admin-plans?filters[admin][id][$eq]=${adminId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      
      console.log('Raw response data:', response.data);
      
      const plansWithEndDate = response.data.data.map(plan => {
        console.log('Processing plan:', plan);
        const startDate = new Date();
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + plan.attributes.renewalPeriodDays);
        return { ...plan, calculatedEndDate: endDate };
      });
  
      setPlans(plansWithEndDate);
      console.log('Fetched plans with calculated end dates:', plansWithEndDate);
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };
  
  

  const handlePlanChange = (e) => {
    const selectedPlanId = e.target.value;
    setSelectedPlan(selectedPlanId);
    const selectedPlanData = plans.find(plan => plan.id === parseInt(selectedPlanId));
    if (selectedPlanData) {
      setFormData(prevData => ({
        ...prevData,
        price_paid: selectedPlanData.attributes.price
      }));
    }
  };
  
  



  useEffect(() => {
    if (editingAgency) {
      setFormData({
        name: editingAgency.attributes.name,
        ownerName: editingAgency.attributes.owner?.data?.attributes?.username || '',
        ownerEmail: editingAgency.attributes.owner?.data?.attributes?.email || '',
        ownerPassword: '',
        confirmPassword: '',
        isActive: editingAgency.attributes.isActive
      });
    } else {
      setFormData({
        name: '',
        ownerName: '',
        ownerEmail: '',
        ownerPassword: '',
        confirmPassword: '',
        isActive: true
      });
    }
  }, [editingAgency]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    if (formData.ownerPassword !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    console.log('Form data before submission:', formData);
  console.log('Selected plan:', selectedPlan);
  console.log('Admin ID:', adminId);
  
    try {
      let response;
      if (editingAgency) {
        response = await axios.put(`${config.API_URL}/api/agencies/${editingAgency.id}`, {
          data: {
            name: formData.name,
            isActive: formData.isActive
          }
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });

        // Fetch users associated with this agency
          const usersResponse = await axios.get(`${config.API_URL}/api/users?filters[agency][id][$eq]=${editingAgency.id}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
          });

          // Update each user's blocked status
  for (const user of usersResponse.data) {
    await axios.put(`${config.API_URL}/api/users/${user.id}`, {
      blocked: !formData.isActive
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
  }




      } else {
        
        const agencyData = {
          data: {
            name: formData.name,
            admin: adminId,
            plan: selectedPlan,
            isActive: formData.isActive
          }
        };
        console.log('Agency data being sent:', agencyData);
  
        response = await axios.post(`${config.API_URL}/api/agencies`, agencyData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
  
        console.log('Agency creation response:', response.data);


  
        // Now create the user with the agency relation
        await axios.post(`${config.API_URL}/api/users`, {
          username: formData.ownerName,
          email: formData.ownerEmail,
          password: formData.ownerPassword,
          role: 4, // Assuming 4 is the role ID for agency owners
          agency: response.data.data.id,
          admin: adminId,
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });

        const selectedPlanData = plans.find(plan => plan.id === parseInt(selectedPlan));
      const startDate = new Date();

      console.log('Selected Plan ID after submit:', selectedPlan);

        // Create entry in AgencySubscriptions
        await axios.post(`${config.API_URL}/api/agency-subscriptions`, {
          data: {
            agency: response.data.data.id,
            admin_plan: selectedPlan,
            admin: adminId,
            startDate: startDate,
            endDate: selectedPlanData.calculatedEndDate,
            isActive: true,
            price_paid: parseFloat(formData.price_paid)
          }
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });


      }

      const completeAgencyData = await axios.get(`${config.API_URL}/api/agencies/${response.data.data.id}?populate=owner`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      
      onSave({
        id: editingAgency.id,
        attributes: {
          name: formData.name,
          isActive: formData.isActive
        }
      });
      onClose();
    } catch (error) {
      console.error('Error saving agency:', error);
      setError(error.response?.data?.error?.message || 'An error occurred while saving');
    }
  };

  if (!isOpen) return null;

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto p-6">
          <h2 className="text-indigo-600 text-2xl mb-4">{editingAgency ? 'Edit Agency' : 'Add New Agency'}</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Agency Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>

            {editingAgency && (
    <div>
      <label className="flex items-center space-x-2">
        <input
          type="checkbox"
          name="isActive"
          checked={formData.isActive}
          onChange={(e) => setFormData(prev => ({ ...prev, isActive: e.target.checked }))}
          className="form-checkbox h-5 w-5 text-indigo-600"
        />
        <span className="text-sm font-medium text-gray-700">Agency Active</span>
      </label>
    </div>
  )}


            {!editingAgency && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Agency Owner Name</label>
                  <input
                    type="text"
                    name="ownerName"
                    value={formData.ownerName}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>

                <div>
        <label className="block text-sm font-medium text-gray-700">Select Plan</label>
        <select
          name="plan"
          value={selectedPlan}
          onChange={handlePlanChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          required
        >
          <option value="">Select a plan</option>
          {plans.map((plan) => (
            <option key={plan.id} value={plan.id}>{plan.attributes.name}</option>
          ))}
        </select>
      </div>

      <div>
  <label className="block text-sm font-medium text-gray-700">Price</label>
  <input
    type="number"
    name="price_paid"
    value={formData.price_paid}
    onChange={handleChange}
    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
    required
  />
</div>


                <div>
                  <label className="block text-sm font-medium text-gray-700">Agency Owner Email</label>
                  <input
                    type="email"
                    name="ownerEmail"
                    value={formData.ownerEmail}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Owner Password</label>
                  <input
                    type="password"
                    name="ownerPassword"
                    value={formData.ownerPassword}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>

                <div>
  <label className="flex items-center space-x-2">
    <input
      type="checkbox"
      name="isActive"
      checked={formData.isActive}
      onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
      className="form-checkbox h-5 w-5 text-indigo-600"
    />
    <span className="text-sm font-medium text-gray-700">Agency Active</span>
  </label>
</div>






              </>
            )}
          </div>
        </div>
        {error && <p className="text-red-500 text-xs italic mb-4 px-6">{error}</p>}
        <div className="flex justify-end p-4 border-t">
          <button onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-800 mr-2">
            Cancel
          </button>
          <button 
            onClick={handleSubmit} 
            className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600"
          >
            {editingAgency ? 'Update' : 'Create'}
          </button>
        </div>
      </div>
    </div>
  );
};  

export default AgencyAccountForm;

