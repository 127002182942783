import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';
import { useBusinessContext } from '../../context/BusinessContext';

const AdminSettings = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { adminId } = useBusinessContext();

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    businessName: ''
  });

  useEffect(() => {
    fetchUserData();
    fetchAdminData();
  }, []);

  const fetchUserData = async () => {
    try {
      console.log('Fetching user data...');
      const response = await axios.get(`${config.API_URL}/api/users/me`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      console.log('User data received:', response.data);
      setFormData(prevData => ({
        ...prevData,
        id: response.data.id,
        username: response.data.username,
        email: response.data.email
      }));
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to load user data');
    }
  };

  const fetchAdminData = async () => {
    try {
      console.log('Fetching admin data...');
      const response = await axios.get(`${config.API_URL}/api/admins/${adminId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      console.log('Admin data received:', response.data);
      if (response.data.data && response.data.data.attributes) {
        setFormData(prevData => ({
          ...prevData,
          name: response.data.data.attributes.name
        }));
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching admin data:', error);
      setError('Failed to load admin data');
      setLoading(false);
    }
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            throw new Error('Invalid email format');
          }


      const userDataToUpdate = {
        username: formData.username,
        email: formData.email
      };
      if (formData.password) {
        userDataToUpdate.password = formData.password;
      }
      await axios.put(
        `${config.API_URL}/api/users/${formData.id}`,
        userDataToUpdate,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        }
      );

      await axios.put(
        `${config.API_URL}/api/admins/${adminId}`,
        { data: { name: formData.name } },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        }
      );
      

      setSuccess('Profile updated successfully');
      setShowSuccessToast(true);
      setTimeout(() => setShowSuccessToast(false), 3000);
      setError('');
      setFormData(prevData => ({ ...prevData, password: '', confirmPassword: '' }));
    } catch (error) {
      console.log('Full error response:', error.response);
      const errorMessage = error.response?.data?.error?.message || error.message;
    setError(`Failed to update profile: ${errorMessage}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Admin Settings</h1>
      <form onSubmit={handleSubmit} className="max-w-md">

      <div className="mb-4">
          <label htmlFor="businessName" className="block text-gray-700 text-sm font-bold mb-2">
            Business Name
          </label>
          <input
            type="text"
            id="businessName"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>


        <div className="mb-4">
          <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">
            Username
          </label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
            New Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block text-gray-700 text-sm font-bold mb-2">
            Confirm New Password
          </label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        

        {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
        
        <button
          type="submit"
          className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Updating...' : 'Update Profile'}
        </button>
      </form>
    
      {showSuccessToast && (
        <div className="fixed bottom-5 right-5 bg-green-500 text-white px-4 py-2 rounded-md">
          {success}
        </div>
      )}
    </div>
  );
};

export default AdminSettings;
