import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';

const BusinessUserForm = ({ isOpen, onClose, onSave, editingUser, businessId, agencyId, adminId }) => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: '',
    isActive: true
  });
  const [error, setError] = useState('');
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    fetchRoles();
  }, []);

  const resetFormData = () => {
  setFormData({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: '',
    isActive: true
  });
};

  useEffect(() => {
    if (editingUser) {
      setFormData({
        username: editingUser.username,
        email: editingUser.email,
        password: '',
        confirmPassword: '',
        role: editingUser.role?.id || '',
        isActive: !editingUser.blocked
      });
    } else {
      setFormData({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: '',
        isActive: true
      });
    }
  }, [editingUser]);

  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/users-permissions/roles`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      setRoles(response.data.roles.filter(role => 
        role.name.toLowerCase() === 'owner' || role.name.toLowerCase() === 'staff'
      ));
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };
  

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      let response;
      if (editingUser) {
        response = await axios.put(`${config.API_URL}/api/users/${editingUser.id}`, {
          username: formData.username,
          email: formData.email,
          role: formData.role,
          blocked: !formData.isActive,
          ...(formData.password && { password: formData.password })
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
      } else {
        response = await axios.post(`${config.API_URL}/api/users`, {
          username: formData.username,
          email: formData.email,
          password: formData.password,
          role: formData.role,
          business: businessId,
          agency: agencyId,
          admin: adminId,
          blocked: !formData.isActive
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
      }

      onSave(response.data);
      resetFormData();
      onClose();
    } catch (error) {
      console.error('Error saving user:', error);
      setError(error.response?.data?.error?.message || 'An error occurred while saving');
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto p-6">
          <h2 className="text-indigo-600 text-2xl mb-4">{editingUser ? 'Edit User' : 'Add New User'}</h2>
          <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Username
            </label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required={!editingUser}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required={!editingUser}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
              Role
            </label>
            <select
              name="role"
              value={formData.role}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Select a role</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>{role.name}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="isActive"
                checked={formData.isActive}
                onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2 text-gray-700">Active</span>
            </label>
          </div>
          {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {editingUser ? 'Update' : 'Create'}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Cancel
            </button>
          </div>
        </form>

        </div>

    

      </div>
    </div>
  );
};


      


export default BusinessUserForm;