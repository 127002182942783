import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';

const DateRangeSelector = ({ dateRange, onRangeSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tempRange, setTempRange] = useState(dateRange);

  const predefinedRanges = [
    { label: 'Today', range: { from: new Date(), to: new Date() } },
    { label: 'Yesterday', range: { from: new Date(new Date().setDate(new Date().getDate() - 1)), to: new Date(new Date().setDate(new Date().getDate() - 1)) } },
    { label: 'This Week', range: { from: startOfWeek(new Date()), to: endOfWeek(new Date()) } },
    { label: 'This Month', range: { from: startOfMonth(new Date()), to: endOfMonth(new Date()) } },
  ];

  const handleRangeSelect = (range) => {
    if (range?.from) {
      if (range.to && range.from > range.to) {
        setTempRange({ from: range.to, to: range.from });
      } else {
        setTempRange(range);
      }
    }
  };

  const handleApply = () => {
    onRangeSelect(tempRange);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setTempRange(dateRange);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        {dateRange.from && dateRange.to
          ? `${format(dateRange.from, 'MMM d, yyyy')} - ${format(dateRange.to, 'MMM d, yyyy')}`
          : 'Select Date Range'}
      </button>
      {isOpen && (
        <div className="absolute right-0 z-20 mt-2 bg-white shadow-lg rounded-lg p-4">
          <div className="flex space-x-2 mb-4">
            {predefinedRanges.map((item, index) => (
              <button
                key={index}
                onClick={() => handleRangeSelect(item.range)}
                className="px-2 py-1 bg-gray-200 text-sm rounded hover:bg-gray-300"
              >
                {item.label}
              </button>
            ))}
          </div>
          <DayPicker
            mode="range"
            selected={tempRange}
            onSelect={handleRangeSelect}
            footer={tempRange.from && tempRange.to && `Selected from ${format(tempRange.from, 'PPP')} to ${format(tempRange.to, 'PPP')}`}
          />
          <div className="mt-4 flex justify-end space-x-2">
            <button
              onClick={handleCancel}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              onClick={handleApply}
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangeSelector;

