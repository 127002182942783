import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';
import { useBusinessContext } from '../../context/BusinessContext';
import AdminPlanForm from './AdminPlanForm';

const AdminPlans = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingPlan, setEditingPlan] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [planToDelete, setPlanToDelete] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { adminId } = useBusinessContext();
  const [error, setError] = useState('');

  useEffect(() => {
    if (adminId) {
      fetchPlans();
    }
  }, [adminId]);

  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/admin-plans?filters[admin][id][$eq]=${adminId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      console.log('API Response:', response);
      console.log('Plans data:', response.data.data);
      setPlans(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching plans:', error);
      console.log('Error response:', error.response);
      setLoading(false);
    }
  };


  const handleDeletePlan = (planId) => {
    setPlanToDelete(planId);
    setShowConfirmModal(true);
  };

  const performDelete = async () => {
    try {
      await axios.delete(`${config.API_URL}/api/admin-plans/${planToDelete}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      setPlans(plans.filter(plan => plan.id !== planToDelete));
      setShowConfirmModal(false);
      setSuccessMessage('Plan deleted successfully');
      setShowSuccessToast(true);
      setTimeout(() => setShowSuccessToast(false), 3000);
    } catch (error) {
      console.error('Error deleting plan:', error);
      setError(error.response?.data?.error?.message || 'An error occurred while deleting');
    }
  };

  const handleEditPlan = (plan) => {
    setEditingPlan(plan);
    setIsFormOpen(true);
  };

  const handleSavePlan = (savedPlan) => {
    if (editingPlan) {
      setPlans(plans.map(p => p.id === savedPlan.id ? savedPlan : p));
      setSuccessMessage('Plan updated successfully');
    } else {
      setPlans([...plans, savedPlan]);
      setSuccessMessage('New plan added successfully');
    }
    setIsFormOpen(false);
    setEditingPlan(null);
    setShowSuccessToast(true);
    setTimeout(() => setShowSuccessToast(false), 3000);
  };

  const handleAddPlan = () => {
    setEditingPlan(null);
    setIsFormOpen(true);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-indigo-600 text-3xl font-bold">Admin Plans</h1>
        <button
          onClick={handleAddPlan}
          className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
        >
          Add New Plan
        </button>
      </div>
      
      {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}

      <div className="bg-white shadow-md rounded my-6">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-100 border-gray-100 text-gray-600 text-sm leading-normal">
              <th className="py-3 px-6 text-left">Plan Name</th>
              <th className="py-3 px-6 text-center">Price</th>
              <th className="py-3 px-6 text-center">Renewal Period (Days)</th>
              <th className="py-3 px-6 text-center">Businesses Allowed</th>
              <th className="py-3 px-6 text-center">Users Allowed</th>
              <th className="py-3 px-6 text-center">Active</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {plans.map((plan) => (
              <tr key={plan.id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  <span className="font-medium">{plan.attributes.name}</span>
                </td>
                <td className="py-3 px-6 text-center">{plan.attributes.price}</td>
                <td className="py-3 px-6 text-center">{plan.attributes.renewalPeriodDays}</td>
                <td className="py-3 px-6 text-center">{plan.attributes.businessesAllowed}</td>
                <td className="py-3 px-6 text-center">{plan.attributes.usersAllowed}</td>
                <td className="py-3 px-6 text-center">
                  {plan.attributes.isActive ? 'Yes' : 'No'}
                </td>
                <td className="py-3 px-6 text-center">
                  <div className="flex item-center justify-center space-x-2">
                    <button 
                      onClick={() => handleEditPlan(plan)} 
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded text-xs"
                    >
                      Edit
                    </button>
                    <button 
                      onClick={() => handleDeletePlan(plan.id)} 
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-xs"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <AdminPlanForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        onSave={handleSavePlan}
        editingPlan={editingPlan}
        adminId={adminId}
      />

      {/* Confirmation Modal */}
      {showConfirmModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Confirm Deletion</h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500">
                  Are you sure you want to delete this plan? This action cannot be undone.
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  id="ok-btn"
                  className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  onClick={performDelete}
                >
                  Delete Plan
                </button>
                <button
                  id="cancel-btn"
                  className="mt-3 px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  onClick={() => setShowConfirmModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Toast */}
      {showSuccessToast && (
        <div className="fixed bottom-5 right-5 bg-green-500 text-white px-4 py-2 rounded-md">
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default AdminPlans;