import React, { useState, useEffect } from 'react';
import ScheduleApi from './ScheduleApi';
import { getUsersByBusiness } from './usersApi';


const ScheduleForm = ({ isOpen, onClose, scheduleItem, cardId, businessId, contactId  }) => {

  const [formData, setFormData] = useState({
    title: '',
    type: '',
    start_time: '',
    end_time: '',
    description: '',
    status: 'Scheduled',
    location: '',
    reminder: false,
    reminder_time: 0,
    card: cardId,
    users_permissions_users: [],
    contact: contactId,
    business: businessId
  });

  useEffect(() => {
    console.log('ScheduleForm: businessId prop:', businessId);
    if (scheduleItem) {

    const formattedStartTime = scheduleItem.attributes.start_time ? new Date(scheduleItem.attributes.start_time).toISOString().slice(0, 16) : '';
    const formattedEndTime = scheduleItem.attributes.end_time ? new Date(scheduleItem.attributes.end_time).toISOString().slice(0, 16) : '';



      setFormData({
        ...scheduleItem.attributes,
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        card: cardId,
        users_permissions_users: scheduleItem.attributes.users_permissions_users?.data?.map(user => user.id) || [],
        contact: scheduleItem.attributes.contact?.data?.id || contactId,
        business: scheduleItem.attributes.business?.data?.id || businessId
      });
    } else {
      resetForm();
    }
  }, [scheduleItem, cardId, businessId]);

  const [users, setUsers] = useState([]);

useEffect(() => {
  const fetchUsers = async () => {
    if (!businessId) {
      console.log('No businessId available, skipping user fetch');
      return;
    }
    try {
      const usersData = await getUsersByBusiness(businessId);
      console.log('Raw response from API:', usersData);
      setUsers(usersData);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]); // Set to empty array in case of error
    }
  };
  fetchUsers();
}, [businessId]);

  

  const resetForm = () => {
    setFormData({
      title: '',
      type: '',
      start_time: '',
      end_time: '',
      description: '',
      status: 'Scheduled',
      location: '',
      reminder: false,
      reminder_time: 0,
      card: cardId,
      users_permissions_users: [],
      contact: contactId,
      business: businessId
    });
  };

  const handleInputChange = (e) => {
  const { name, value, options } = e.target;
  if (name === 'users_permissions_users') {
    const selectedUsers = Array.from(options)
      .filter(option => option.selected)
      .map(option => option.value);
    setFormData(prevData => ({
      ...prevData,
      [name]: selectedUsers
    }));
  } else {
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }
};



  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Form data before cleaning:', formData);
  
      // Remove undefined or null values and empty arrays
      const cleanedFormData = Object.entries(formData).reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null && !(Array.isArray(value) && value.length === 0)) {
          acc[key] = value;
        }
        return acc;
      }, {});
  
      console.log('Cleaned Form data being submitted:', cleanedFormData);
  
      // Ensure business is set
      if (!cleanedFormData.business) {
        console.log('Setting business ID:', businessId);
        cleanedFormData.business = businessId;
      }
  
      // Ensure card is set
      cleanedFormData.card = cardId;
  
      // Convert specific fields to numbers
      cleanedFormData.business = Number(cleanedFormData.business);
      cleanedFormData.card = Number(cleanedFormData.card);
      cleanedFormData.reminder_time = Number(cleanedFormData.reminder_time);
  
      // CHANGE: Convert date strings to ISO format

// In ScheduleForm.js, within the handleSubmit function

if (cleanedFormData.start_time) {
  const date = new Date(cleanedFormData.start_time);
  cleanedFormData.start_time = date.toISOString();
}
if (cleanedFormData.end_time) {
  const date = new Date(cleanedFormData.end_time);
  cleanedFormData.end_time = date.toISOString();
} else {
  // If end_time is empty, remove it from the payload
  delete cleanedFormData.end_time;
}

      

  
      // Validate required fields
      if (!cleanedFormData.title || !cleanedFormData.type || !cleanedFormData.start_time) {
        throw new Error('Please fill in all required fields (Title, Type, Start Time)');
      }
  
      const payload = cleanedFormData;
  
      console.log('Payload being sent to ScheduleApi:', JSON.stringify(payload, null, 2));
  
      if (scheduleItem) {
        await ScheduleApi.updateSchedule(scheduleItem.id, payload);
      } else {
        await ScheduleApi.createSchedule(payload);
      }
      onClose(true);
      resetForm();
    } catch (error) {
      console.error('Error saving schedule:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      alert(error.message || 'An error occurred while saving the schedule. Please try again.');
    }
  };



  if (!isOpen) return null;

  return (
    <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto p-6">
          <h2 className="text-2xl font-bold mb-4">{scheduleItem ? 'Edit Schedule' : 'Add New Schedule'}</h2>
        <form onSubmit={handleSubmit}>

        <div className="mb-4">
  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user">
    Assigned User
  </label>
  <select
    id="users_permissions_users"
    name="users_permissions_users"
    multiple
    value={formData.users_permissions_users}
    onChange={handleInputChange}
    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
  >
    <option value="">Select a user</option>
    {users && users.length > 0 ? (
      users.map(user => (
        <option key={user.id} value={user.id}>
          {user.username}
        </option>
      ))
    ) : (
      <option value="" disabled>Loading users...</option>
    )}
  </select>
</div>



          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">
              Type
            </label>
            <select
              id="type"
              name="type"
              value={formData.type}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Select Type</option>
              <option value="Call">Call</option>
              <option value="Meeting">Meeting</option>
              <option value="Event">Event</option>
              <option value="Task">Task</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="start_time">
              Start Time
            </label>
            <input
              type="datetime-local"
              id="start_time"
              name="start_time"
              value={formData.start_time}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="end_time">
              End Time
            </label>
            <input
              type="datetime-local"
              id="end_time"
              name="end_time"
              value={formData.end_time}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows="3"
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
              Status
            </label>
            <select
              id="status"
              name="status"
              value={formData.status}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="Scheduled">Scheduled</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Rescheduled">Rescheduled</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="location">
              Location
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="reminder"
                checked={formData.reminder}
                onChange={handleInputChange}
                className="mr-2"
              />
              <span className="text-gray-700 text-sm font-bold">Set Reminder</span>
            </label>
          </div>
          {formData.reminder && (
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="reminder_time">
                Reminder Time (minutes before)
              </label>
              <input
                type="number"
                id="reminder_time"
                name="reminder_time"
                value={formData.reminder_time}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                min="0"
              />
            </div>
          )}

        </form>


      </div>
      <div className="flex justify-end p-4 border-t">
        <button onClick={() => onClose(false)} className="px-4 py-2 text-gray-600 hover:text-gray-800 mr-2">
          Cancel
        </button>
        <button onClick={(e) => handleSubmit(e)} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          Save
        </button>
      </div>
    </div>
  </div>
);
};

export default ScheduleForm;