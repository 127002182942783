import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate  } from 'react-router-dom';
import config from '../../config/config';
import { Link } from 'react-router-dom';
import AgencyBusinessForm from './AgencyBusinessForm';
import { useBusinessContext } from '../../context/BusinessContext';

const AgencyBusinesses = () => {
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const { agencyId } = useParams();
  const { adminId } = useBusinessContext();

  const navigate = useNavigate();

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [editingBusiness, setEditingBusiness] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [businessToDelete, setBusinessToDelete] = useState(null);

  const [isFormOpen, setIsFormOpen] = useState(false);

  const [agencyName, setAgencyName] = useState('');




  useEffect(() => {
    fetchBusinesses();
  }, [agencyId]);

  const fetchBusinesses = async () => {
    try {

      const agencyResponse = await axios.get(`${config.API_URL}/api/agencies/${agencyId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      setAgencyName(agencyResponse.data.data.attributes.name);

      console.log('Agency response:', agencyResponse);


      const response = await axios.get(`${config.API_URL}/api/businesses?filters[agency][id][$eq]=${agencyId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });

      console.log('Businesses response:', response.data);

      const businessesWithUserCount = await Promise.all(response.data.data.map(async (business) => {
        console.log(`Fetching users for business ${business.id}...`);
        const usersResponse = await axios.get(`${config.API_URL}/api/users?filters[business][id][$eq]=${business.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
        console.log(`Users response for business ${business.id}:`, usersResponse.data);
        return { 
          ...business, 
          userCount: Array.isArray(usersResponse.data) ? usersResponse.data.length : 0
        };
      }));
      
      console.log('Businesses with user count:', businessesWithUserCount);
      setBusinesses(businessesWithUserCount);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching businesses:', error);
      setLoading(false);
    }
  };

  const handleAddBusiness = () => {
    setEditingBusiness(null);
    setIsFormOpen(true);
  };

  const handleSaveBusiness = (savedBusiness) => {
    if (editingBusiness) {
      setBusinesses(businesses.map(business => {
        if (business.id === savedBusiness.id) {
          return {
            ...business,
            attributes: {
              ...business.attributes,
              ...savedBusiness.attributes
            },
            userCount: business.userCount // Preserve the user count
          };
        }
        return business;
      }));
      setSuccessMessage('Business updated successfully');
    } else {
      setBusinesses([...businesses, savedBusiness]);
      setSuccessMessage('New business added successfully');
    }
    setIsFormOpen(false);
    setEditingBusiness(null);
    setShowSuccessToast(true);
    setTimeout(() => setShowSuccessToast(false), 3000);
  };
  

  
  

  

  const handleEditBusiness = (business) => {
    setEditingBusiness(business);
    setIsFormOpen(true);
  };
  

const handleDeleteBusiness = (businessId) => {
  setBusinessToDelete(businessId);
  setShowConfirmModal(true);
};

const performDelete = async () => {
  try {
    await axios.delete(`${config.API_URL}/api/businesses/${businessToDelete}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
    setBusinesses(businesses.filter(business => business.id !== businessToDelete));
    setShowConfirmModal(false);
    // Show success message
  } catch (error) {
    console.error('Error deleting business:', error);
    // Show error message
  }
};



  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
    <div>
    {agencyName && (
      <h1 className="text-indigo-600 text-3xl font-bold">{agencyName}</h1>
    )}
    </div>
    <div className="flex space-x-4">
      <button
        onClick={() => navigate(-1)}
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
      >
        Back
      </button>
      <button
        onClick={handleAddBusiness}
        className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
      >
        Add New Business
      </button>
    </div>
  </div>


      <div className="bg-white shadow-md rounded my-6">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-100 border-gray-100 text-gray-600 text-sm leading-normal">
              <th className="py-3 px-6 text-left">Business Name</th>
              <th className="py-3 px-6 text-center">Users Count</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {businesses.map((business) => (
              <tr key={business.id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  <span className="font-medium">{business.attributes.name}</span>
                </td>

                <td className="py-3 px-6 text-center">
          <span className="font-medium">{business.userCount}</span>
        </td>

        
        <td className="py-3 px-6 text-right">
        <div className="flex item-center justify-center space-x-2">
  <button
    className={`font-bold py-1 px-2 rounded text-xs ${
      business.userCount > 0
        ? 'bg-blue-500 hover:bg-blue-700 text-white'
        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
    }`}
    disabled={business.userCount === 0}
    onClick={() => business.userCount > 0 && navigate(`/admin/businesses/${business.id}/users`)}
  >
    View Users
  </button>

  <div className="flex item-center justify-center">
    <button onClick={() => handleEditBusiness(business)} className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded text-xs mr-2">
      Edit
    </button>
    <button onClick={() => handleDeleteBusiness(business.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-xs">
      Delete
    </button>
  </div>

  </div>

</td>

                
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <AgencyBusinessForm
  isOpen={isFormOpen}
  onClose={() => setIsFormOpen(false)}
  onSave={handleSaveBusiness}
  editingBusiness={editingBusiness}
  agencyId={agencyId}
  adminId={adminId}
/>




            {/* Confirmation Modal */}
            {showConfirmModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Confirm Deletion</h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500">
                  Are you sure you want to delete this agency? This action cannot be undone.
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  id="ok-btn"
                  className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  onClick={performDelete}
                >
                  Delete Agency
                </button>
                <button
                  id="cancel-btn"
                  className="mt-3 px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  onClick={() => setShowConfirmModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Toast */}
      {showSuccessToast && (
        <div className="fixed bottom-5 right-5 bg-green-500 text-white px-4 py-2 rounded-md">
          {successMessage}
        </div>
      )}

    </div>
  );
};

export default AgencyBusinesses;

