import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';
import { useBusinessContext } from '../../context/BusinessContext';

const AgencyDashboard = () => {
  const [totalRevenue, setTotalRevenue] = useState({ sum: 0, count: 0 });
  const [pendingPayment, setPendingPayment] = useState({ count: 0, sum: 0 });
  const [upcomingPayment, setUpcomingPayment] = useState({ count: 0, sum: 0 });
  const [mrr, setMRR] = useState(0);
  const [wrr, setWRR] = useState(0);
  const [arpa, setARPA] = useState(0);
  const [loading, setLoading] = useState(true);
  const { agencyId } = useBusinessContext();
  const [totalBusinesses, setTotalBusinesses] = useState(0);
  const [businessesLast30Days, setBusinessesLast30Days] = useState(0);
  const [businessesLast7Days, setBusinessesLast7Days] = useState(0);
  const [last30DaysRevenue, setLast30DaysRevenue] = useState(0);
  const [last7DaysRevenue, setLast7DaysRevenue] = useState(0);
  const [todayRevenue, setTodayRevenue] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.API_URL}/api/business-subscriptions?filters[agency][id][$eq]=${agencyId}&populate=agency_plan,business`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
        
        const businessesResponse = await axios.get(`${config.API_URL}/api/businesses?filters[agency][id][$eq]=${agencyId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
    
        const currentDate = new Date();
        const sevenDaysFromNow = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        const thirtyDaysAgo = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
        const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        let revenueSum = 0;
        let revenueCount = 0;
        let pendingCount = 0;
        let pendingSum = 0;
        let upcomingCount = 0;
        let upcomingSum = 0;
        let mrrSum = 0;
        let wrrSum = 0;
        let last30DaysSum = 0;
        let last7DaysSum = 0;
        let todaySum = 0;
        const uniqueBusinesses = new Set();
    
        response.data.data.forEach(subscription => {
          const price = subscription.attributes.price_paid;
          const renewalPeriod = subscription.attributes.agency_plan.data.attributes.renewalPeriodDays;
          const subscriptionDate = new Date(subscription.attributes.createdAt);
          
          revenueSum += price;
          revenueCount++;
          uniqueBusinesses.add(subscription.attributes.business.data.id);
          
          const endDate = new Date(subscription.attributes.endDate);
          if (endDate < currentDate) {
            pendingCount++;
            pendingSum += price;
          } else if (endDate <= sevenDaysFromNow) {
            upcomingCount++;
            upcomingSum += price;
          }
    
          if (subscription.attributes.isActive) {
            mrrSum += (price / renewalPeriod) * 30;
            wrrSum += (price / renewalPeriod) * 7;
          }
    
          if (subscriptionDate >= thirtyDaysAgo) {
            last30DaysSum += price;
            if (subscriptionDate >= sevenDaysAgo) {
              last7DaysSum += price;
              if (subscriptionDate.toDateString() === currentDate.toDateString()) {
                todaySum += price;
              }
            }
          }
        });
    
        const businesses = businessesResponse.data.data;
        const totalBusinesses = businesses.length;
        const last30DaysCount = businesses.filter(business => new Date(business.attributes.createdAt) >= thirtyDaysAgo).length;
        const last7DaysCount = businesses.filter(business => new Date(business.attributes.createdAt) >= sevenDaysAgo).length;
    
        setTotalRevenue({ sum: revenueSum, count: revenueCount });
        setPendingPayment({ count: pendingCount, sum: pendingSum });
        setUpcomingPayment({ count: upcomingCount, sum: upcomingSum });
        setMRR(mrrSum);
        setWRR(wrrSum);
        setARPA(revenueSum / uniqueBusinesses.size);
        setTotalBusinesses(totalBusinesses);
        setBusinessesLast30Days(last30DaysCount);
        setBusinessesLast7Days(last7DaysCount);
        setLast30DaysRevenue(last30DaysSum);
        setLast7DaysRevenue(last7DaysSum);
        setTodayRevenue(todaySum);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    if (agencyId) {
      fetchData();
    }
  }, [agencyId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Agency Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">Total Revenue</h2>
          <p className="text-4xl font-bold text-indigo-600">Rs.{totalRevenue.sum.toFixed(2)}</p>
          <p className="text-lg text-gray-600">({totalRevenue.count} subscriptions)</p>
        </div>
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">Pending Payment</h2>
          <p className="text-4xl font-bold text-red-600">Rs.{pendingPayment.sum.toFixed(2)}</p>
          <p className="text-lg text-gray-600">({pendingPayment.count} subscriptions)</p>
        </div>
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">Upcoming Payment</h2>
          <p className="text-4xl font-bold text-yellow-600">Rs.{upcomingPayment.sum.toFixed(2)}</p>
          <p className="text-lg text-gray-600">({upcomingPayment.count} subscriptions)</p>
        </div>
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">Last 30 Days Revenue</h2>
          <p className="text-4xl font-bold text-green-600">Rs. {last30DaysRevenue.toFixed(2)}</p>
        </div>
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">Last 7 Days Revenue</h2>
          <p className="text-4xl font-bold text-blue-600">Rs. {last7DaysRevenue.toFixed(2)}</p>
        </div>
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">Today's Revenue</h2>
          <p className="text-4xl font-bold text-purple-600">Rs. {todayRevenue.toFixed(2)}</p>
        </div>
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">Monthly Recurring Revenue (MRR)</h2>
          <p className="text-4xl font-bold text-green-600">Rs.{mrr.toFixed(2)}</p>
        </div>
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">Weekly Recurring Revenue (WRR)</h2>
          <p className="text-4xl font-bold text-blue-600">Rs.{wrr.toFixed(2)}</p>
        </div>
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">Average Revenue per Business (ARPA)</h2>
          <p className="text-4xl font-bold text-purple-600">Rs.{arpa.toFixed(2)}</p>
        </div>
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">Total Businesses</h2>
          <p className="text-4xl font-bold text-blue-600">{totalBusinesses}</p>
        </div>
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">New Businesses (30 days)</h2>
          <p className="text-4xl font-bold text-green-600">{businessesLast30Days}</p>
        </div>
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">New Businesses (7 days)</h2>
          <p className="text-4xl font-bold text-orange-600">{businessesLast7Days}</p>
        </div>
      </div>
    </div>
  );
};

export default AgencyDashboard;