import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';

const AgencyUserForm = ({ isOpen, onClose, onSave, editingUser, agencyId }) => {
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    role: '',
    blocked: false,
    agency: agencyId
  });
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (editingUser) {
      setUserData({
        username: editingUser.username,
        email: editingUser.email,
        role: editingUser.role?.id || '',
        blocked: editingUser.blocked
      });
    } else {
      setUserData({
        username: '',
        email: '',
        role: '',
        blocked: false,
        password: '',
        confirmPassword: ''
      });
    }
    setErrorMessage('');
    fetchRoles();
  }, [editingUser, agencyId]);

  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/users-permissions/roles`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      const filteredRoles = response.data.roles.filter(role => 
        role.name.toLowerCase() === 'owner' || role.name.toLowerCase() === 'staff'
      );
      setRoles(filteredRoles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSave = async () => {
    if (!userData.username || !userData.email || !userData.role) {
      setErrorMessage('Enter all the required details.');
      return;
    }
    if (!isValidEmail(userData.email)) {
      setErrorMessage('Please enter a valid email address');
      return;
    }
    if (!editingUser && (!userData.password || userData.password.length < 6)) {
      setErrorMessage('Password must be at least 6 characters long');
      return;
    }
    if (userData.password !== userData.confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }
    setErrorMessage('');
    setIsLoading(true);
    try {
      let response;
      let payload = { ...userData, agency: agencyId };

      if (payload.password) {
        if (payload.password !== payload.confirmPassword) {
          setErrorMessage('Passwords do not match');
          setIsLoading(false);
          return;
        }
      } else {
        delete payload.password;
        delete payload.confirmPassword;
      }

      if (editingUser) {
        response = await axios.put(`${config.API_URL}/api/users/${editingUser.id}`, payload, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
      } else {
        response = await axios.post(`${config.API_URL}/api/users`, payload, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
      }
      onSave(response.data);
    } catch (error) {
      setErrorMessage(error.response?.data?.error?.message || 'An error occurred while saving');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto p-6">
          <h2 className="text-indigo-600 text-2xl mb-4">{editingUser ? 'Edit User' : 'Add New User'}</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Username</label>
              <input
                type="text"
                name="username"
                value={userData.username}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={userData.email}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Role</label>
              <select
                name="role"
                value={userData.role}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              >
                <option value="">Select a role</option>
                {roles.map(role => (
                  <option key={role.id} value={role.id}>{role.name}</option>
                ))}
              </select>
            </div>
            {!editingUser && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Password</label>
                  <input
                    type="password"
                    name="password"
                    value={userData.password}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={userData.confirmPassword}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  />
                </div>
              </>
            )}
            <div>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="blocked"
                  checked={userData.blocked}
                  onChange={handleInputChange}
                  className="mr-2"
                />
                <span className="text-sm">Blocked</span>
              </label>
            </div>
          </div>
        </div>
        {errorMessage && (
          <div className="m-4 mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
            {errorMessage}
          </div>
        )}
        <div className="flex justify-end p-4 border-t">
          <button onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-800 mr-2">
            Cancel
          </button>
          <button 
            onClick={handleSave} 
            className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600"
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};


export default AgencyUserForm;
