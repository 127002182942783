import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';
import { useBusinessContext } from '../../context/BusinessContext';
import AgencyAccountForm from './AgencyAccountForm';
import { Link , useNavigate } from 'react-router-dom';


const AgencyAccounts = () => {
  const [agencies, setAgencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingAgency, setEditingAgency] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [agencyToDelete, setAgencyToDelete] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { adminId } = useBusinessContext();
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [confirmationInput, setConfirmationInput] = useState('');

  const handleConfirmationInputChange = (e) => {
  setConfirmationInput(e.target.value);
};

  


  useEffect(() => {
    if (adminId) {
      fetchAgencies();
    }
  }, [adminId]);


  

  const fetchAgencies = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/agencies?filters[admin][id][$eq]=${adminId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      console.log('Agencies response:', response.data);

      // Fetch user count for each agency
      const agenciesWithUserCount = await Promise.all(response.data.data.map(async (agency) => {
        console.log(`Fetching data for agency ${agency.id}...`);
        
        const usersResponse = await axios.get(`${config.API_URL}/api/users?filters[agency][id][$eq]=${agency.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
        console.log(`Users response for agency ${agency.id}:`, usersResponse.data);

        

        const businessesResponse = await axios.get(`${config.API_URL}/api/businesses?filters[agency][id][$eq]=${agency.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
        console.log(`Businesses response for agency ${agency.id}:`, businessesResponse.data);

        const subscriptionResponse = await axios.get(`${config.API_URL}/api/agency-subscriptions?filters[agency][id][$eq]=${agency.id}&sort=endDate:desc&populate=admin_plan`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
        
        
        const latestSubscription = subscriptionResponse.data.data[0] || {};
const planName = latestSubscription?.attributes?.admin_plan?.data?.attributes?.name || 'N/A';
const dueDate = latestSubscription?.attributes?.endDate ? new Date(latestSubscription.attributes.endDate) : null;

        console.log(`Subscriptions for agency ${agency.id}:`, subscriptionResponse.data);

        const calculateDaysLeft = (dueDate) => {
          if (!dueDate) return null;
          const today = new Date();
          const timeDiff = dueDate.getTime() - today.getTime();
          return Math.ceil(timeDiff / (1000 * 3600 * 24));
        };

        const daysLeft = calculateDaysLeft(dueDate);

        console.log(`Plan Name: ${agency.id}:`, planName);


        return { 
          ...agency, 
          userCount: usersResponse.data.length,
          businessCount: businessesResponse.data.meta.pagination.total,
          dueDate: dueDate,
          daysLeft: daysLeft,
          planName: planName
         };
      }));

      setAgencies(agenciesWithUserCount);

      console.log('Agencies Data:',agenciesWithUserCount)

      //setAgencies(response.data.data);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching agencies:', error);
      setLoading(false);
    }
  };
  
  const handleDeleteAgency = (agencyId) => {
    setAgencyToDelete(agencyId);
    setShowConfirmModal(true);
  };

  const performDelete = async () => {
    try {
      // Fetch users associated with the agency
      console.log('Fetching users for agency:', agencyToDelete);
      const usersResponse = await axios.get(`${config.API_URL}/api/users?filters[agency][id][$eq]=${agencyToDelete}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      console.log('User fetch response:', usersResponse.data);
      const users = usersResponse.data;
      console.log('Users to delete:', users);
  
      // Delete each user individually
      if (Array.isArray(users)) {
        for (const user of users) {
          console.log('Deleting user:', user.id);
          await axios.delete(`${config.API_URL}/api/users/${user.id}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
          });
          console.log('User deleted successfully:', user.id);
        }
      } else {
        console.log('No users found or users data is not in expected format');
      }
  
      // Fetch businesses associated with the agency
      const businessesResponse = await axios.get(`${config.API_URL}/api/businesses?filters[agency][id][$eq]=${agencyToDelete}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
      const businesses = businessesResponse.data.data || [];
  
      // Delete each business individually
      if (Array.isArray(businesses)) {
        for (const business of businesses) {
          await axios.delete(`${config.API_URL}/api/businesses/${business.id}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
          });
        }
      } else {
        console.log('No businesses found or businesses data is not in expected format');
      }

      // Fetch and delete agency subscriptions
    const subscriptionsResponse = await axios.get(`${config.API_URL}/api/agency-subscriptions?filters[agency][id][$eq]=${agencyToDelete}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
    });
    const subscriptions = subscriptionsResponse.data.data || [];

    if (Array.isArray(subscriptions)) {
      for (const subscription of subscriptions) {
        await axios.delete(`${config.API_URL}/api/agency-subscriptions/${subscription.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
        });
      }
    } else {
      console.log('No agency subscriptions found or data is not in expected format');
    }
  
      // Finally, delete the agency
      await axios.delete(`${config.API_URL}/api/agencies/${agencyToDelete}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
      });
  
      setAgencies(agencies.filter(agency => agency.id !== agencyToDelete));
      setShowConfirmModal(false);
      setConfirmationInput('');
      setSuccessMessage('Agency and related users and businesses deleted successfully');
      setShowSuccessToast(true);
      setTimeout(() => setShowSuccessToast(false), 3000);
    } catch (error) {
      console.error('Detailed error:', error.response?.data || error.message);
      setError(error.response?.data?.error?.message || 'An error occurred while deleting. Check console for details.');
    }
  };
  

  
  

  const handleEditAgency = (agency) => {
    setEditingAgency(agency);
    setIsFormOpen(true);
  };

  const handleSaveAgency = (savedAgency) => {
    if (editingAgency) {
      setAgencies(agencies.map(agency => {
        if (agency.id === savedAgency.id) {
          return {
            ...agency,
            attributes: {
              ...agency.attributes,
              ...savedAgency.attributes
            }
          };
        }
        return agency;
      }));
      setSuccessMessage('Agency updated successfully');
    } else {
      setAgencies([...agencies, savedAgency]);
      setSuccessMessage('New agency added successfully');
    }
    setIsFormOpen(false);
    setEditingAgency(null);
    setShowSuccessToast(true);
    setTimeout(() => setShowSuccessToast(false), 3000);
  };
  
  const handleAddAgency = () => {
    setEditingAgency(null);
    setIsFormOpen(true);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-indigo-600 text-3xl font-bold">Agency Accounts</h1>
        <button
          onClick={handleAddAgency}
          className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
        >
          Add New Agency
        </button>
      </div>
      
      {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}

      <div className="bg-white shadow-md rounded my-6">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-100 border-gray-100 text-gray-600 text-sm leading-normal">
              <th className="py-3 px-6 text-left">Agency Name</th>
              <th className="py-3 px-6 text-center">Due Date</th>
              <th className="py-3 px-6 text-center">Days Left</th>
              <th className="py-3 px-6 text-center">Plan Name</th>
              <th className="py-3 px-6 text-center">Total Businesses</th>
              <th className="py-3 px-6 text-center">Total Users</th>
              <th className="py-3 px-6 text-center">Status</th>

              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
          {agencies && agencies.length > 0 ? (
            agencies.map((agency) => (
              <tr key={agency.id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  <div className="flex items-center">
                    <span className="font-medium">{agency.attributes?.name || 'N/A'}</span>
                  </div>
                </td>

                <td className="py-3 px-6 text-center">
  <span className="font-medium">
    {agency.dueDate ? agency.dueDate.toLocaleDateString() : 'N/A'}
  </span>
</td>
<td className="py-3 px-6 text-center">
  {agency.dueDate ? (
    agency.daysLeft > 0 ? (
      <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs font-semibold">
        {agency.daysLeft} days left
      </span>
    ) : (
      <span className="bg-red-100 text-red-800 px-2 py-1 rounded-full text-xs font-semibold">
        Overdue by {Math.abs(agency.daysLeft)} days
      </span>
    )
  ) : (
    'N/A'
  )}
</td>



                {/* <td className="py-3 px-6 text-center">
  <span className="font-medium" title={agency.planName}>
    {agency.planName.length > 10 ? agency.planName.slice(0, 10) + '...' : agency.planName}
  </span>
</td> */}

<td className="py-3 px-6 text-center">
                  <span className="font-medium">{agency.planName}</span>
                </td>




                <td className="py-3 px-6 text-center">
                  <span className="font-medium">{agency.businessCount}</span>
                </td>
                <td className="py-3 px-6 text-center">
                <span className="font-medium">{agency.userCount}</span>
              </td>

              <td className="py-3 px-6 text-center">
  <span className={`px-2 py-1 rounded ${agency.attributes.isActive ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
    {agency.attributes.isActive ? 'Active' : 'Inactive'}
  </span>
</td>

              <td className="py-3 px-6 text-right">
  <div className="flex item-center justify-center space-x-2">
  <button
      onClick={() => navigate(`/admin/agencies/${agency.id}/businesses`)}
      className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded text-xs"
    >
      View
    </button>
    
    {/* <Link 
      to={`/admin/agencies/${agency.id}`}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs"
    >
      View Users
    </Link> */}

    <button 
      onClick={() => handleEditAgency(agency)} 
      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded text-xs"
    >
      Edit
    </button>
    <button 
      onClick={() => handleDeleteAgency(agency.id)} 
      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-xs"
    >
      Delete
    </button>
  </div>
</td>


              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center py-4">No agencies found</td>
            </tr>
          )}
          </tbody>
        </table>
      </div>

      <AgencyAccountForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        onSave={handleSaveAgency}
        editingAgency={editingAgency}
      />

      {/* Confirmation Modal */}
      {showConfirmModal && (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      <div className="mt-3 text-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Confirm Deletion</h3>
        <div className="mt-2 px-7 py-3">
          <p className="text-sm text-gray-500">
            Warning: This action will delete the agency and all associated data. This cannot be undone.
          </p>
          <p className="text-sm text-gray-500 mt-2">
            Type "Apply Destructive Action" to confirm:
          </p>
          <input
            type="text"
            value={confirmationInput}
            onChange={handleConfirmationInputChange}
            className="mt-2 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div className="items-center px-4 py-3">
          <button
            id="ok-btn"
            className={`px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full ${confirmationInput !== 'Apply Destructive Action' ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={performDelete}
            disabled={confirmationInput !== 'Apply Destructive Action'}
          >
            Delete Agency
          </button>
          <button
            id="cancel-btn"
            className="mt-2 px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full"
            onClick={() => {
              setShowConfirmModal(false);
              setConfirmationInput('');
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
)}

      {/* Success Toast */}
      {showSuccessToast && (
        <div className="fixed bottom-5 right-5 bg-green-500 text-white px-4 py-2 rounded-md">
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default AgencyAccounts;
